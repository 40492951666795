<template>
    <div class="home">
        <div class="summary">
            <span>
                The RAre Variant Association Repository (RAVAR) serves as a meticulously curated knowledgebase, offering a comprehensive collection of rare variant associations at both the gene and variant levels.
            </span>
        </div>

        <div class="search-box">
            <t-select
                v-model="searchId"
                @search="handleSearch"
                @focus="handleInputFocus"
                :options="searchOption"
                class="search-all"
                placeholder="eg: dimethylglycine measurement, KIF1B, ENSG00000054523, rs112849259"
            />
            <div @click="handleClickSearch"><i class="fas fa-search" style="cursor: pointer;"></i></div>
        </div>

        <div class="stock-box">
            <a class="stock-box-item" v-for="item in titleList" :key="item.label" :href="item.url" >
                <p>{{ item.label }}</p>
                <p>{{ item.value }}</p>
            </a>
        </div>

        <div class="desc-box">
            <div class="desc-item">
                <div class="title">What is a Rare variant-trait association?</div>
                <div class="content">
                    Methods for rare variant association tests aggregate rare variants into distinct
                    units for testing, rare variant association tests then examine the distribution
                    of these rare variants between the case and control groups. Rare variants
                    contribute significantly to complex traits and can have effects that are a
                    magnitude greater than common variants, and account for much of the missing
                    heritability unexplained by GWAS.
                </div>
            </div>
            <div class="desc-item">
                <div class="title">About the RAVAR</div>
                <div class="content">
                    Rare Variant Association Repository (RAVAR), is an open database of published
                    trait-rare variants association, both at gene-level and variant level. Each
                    association in RAVAR is consistently annotated with relevant metadata; including
                    association information (variants, gene, effect alleles, MAF, 95% CI, p-value),
                    annotations of how the rare variant association test was applied. RAVAR is the
                    first resource to collect and curate published rare variant associations at both
                    the gene and variant level in a visualized, interactive format.
                </div>
            </div>
        </div>
        <div class="desc-box">
            <div class="desc-item">
                <div class="title">Downloads</div>
                <div class="content">
                    Bulk downloads of RAVAR variant-trait associations files at gene-level or variant level, or interest at specific trait, are available from the website: <router-link to="/downloads">Downloads</router-link>
                </div>
            </div>
            <div class="desc-item">
                <div class="title">Feedback & Contact</div>
                <div class="content">
                    Provide feedback, or ask questions: contact the RAVAR team at caochen@njmu.edu.cn, zouquan@nclab.net
                </div>
            </div>
        </div>

        <div style="font-size: 20px; margin-bottom: 16px; text-align: center">
            Development of the RAVAR is mainly supported by:
        </div>
        <div class="img-box">
            <img src="/images/img4.jpg" />
            <img src="/images/img3.png" />
            <img src="/images/img1.png" />
            <img src="/images/img2.png" />

        </div>
        <!--
        <div class="new-box">
            <div class="new-item">
                <div class="new-title">Latest News</div>
                <p>
                    TWAS Atlas version 1.0 was online on 2022-05-15 and knowledge graph was
                    constructed with curated associations
                </p>
            </div>
            <div class="new-item">
                <div class="new-title">Citation and Contact</div>
                <p>
                    TWAS Atlas version 1.0 was online on 2022-05-15 and knowledge graph was
                    constructed with curated associations
                </p>
            </div>
        </div>
        -->
    </div>
</template>

<script>
const titleList = [
    {
        label: "Traits",
        value: "4,429",
        key: "traitCount",
        url: '/#/browseTraits'
    },
    {
        label: "Associations（Gene）",
        value: "76,186",
        key: "associationCount",
        url: '/#/browseGenes'
    },
    {
        label: "Associations（Variant）",
        value: "18,861",
        key: "snpCount",
        url: '/#/browseSnp'
    },
    {
        label: "Publications",
        value: "245",
        key: "publicationCount",
        url: '/#/browsePublications'
    },
];

export default {
    name: "Home",
    components: {},
    data() {
        return {
            titleList,
            searchId: "",
            searchOption: [],
        };
    },
    created() {
        this.getHomeInfo();
    },

    methods: {
        handleClickSearch() {
            if (!this.searchId) return;

            let item = this.searchOption.find(v => v.value === this.searchId);
            const urlMap = {
                trait: "trait",
                gene: "genedetail",
                publication: "publication",
                snp: "snp",
            };

            this.$router.push({
                name: urlMap[item.type],
                params: {
                    id: item.id,
                }
            });
        },
        async handleSearch(val) {
            let params = {
                keyword: val,
                pageSize: 20,
            };
            let res = await this.$ajax.get("/trait/search", params);

            this.searchOption = (res.data.records || []).map((v) => {
                return {
                    ...v,
                    label: v.name,
                    value: v.id,
                };
            });
        },

        handleInputFocus() {},

        // async getHomeInfo() {
        //     let res = await this.$ajax.get("/statistics/home");
        //     const data = res.data;
        //     this.titleList.forEach((item) => {
        //         item.value = data[item.key] || 0;
        //     });
        // },
    },
};
</script>

<style lang="less" scoped>
.home {
    margin: 80px auto 60px;
    width: 85%;
}

.summary {
    margin-top: 15px;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 1.8;
    text-align: justify;
}

.stock-box {
    display: grid;
    grid-template-columns: repeat(5, 25%);
    grid-template-rows: 160px;
    .stock-box-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #ffffff;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        line-height: 1.6;
        background: #32587f;
        text-decoration: none;

        &:nth-child(2n) {
            color: #268483;
            background: #fffaeb;
        }
    }
}

.new-box {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    .new-item {
        height: 200px;
        width: calc(50% - 50px);
        border: 1px solid #dddddd;

        .new-title {
            padding: 15px 15px;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            background: #f5f5f5;
        }
    }
}

.desc-box {
    display: flex;
    justify-content: space-between;
    margin: 50px 0;

    .desc-item {
        width: 50%;
        padding: 0 20px;
        &:not(:last-child) {
            border-right: 1px solid #be4a81;
        }
        .title {
            color: #007c82;
            margin-bottom: 16px;
            text-align: center;
            font-size: 28px;
        }

        .content {
            font-size: 20px;
        }
    }
}

.img-box {
    display: flex;
    justify-content: space-evenly;

    img {
        width: 150px;
    }
}

.search-box {
    position: relative;
    i {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translate(0, -50%);
        font-size: 24px;
    }
}

.search-all {
    margin-bottom: 30px;

    &.t-select {
        /deep/ .select-input {
            &::after {
                display: none;
            }
        }
    }
}
</style>
