<template>
    <div class="container-search row">
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
            <div class="searchB">
                <div class="searchN">
                    <span><i class="fas fa-search-location"></i>Search by Trait</span>
                </div>
                <div class="searchSub">
                    <label>
                        <span>Trait</span>
                    </label>
                    <t-select
                        :disabled="searchDisabled.trait"
                        class="search-select"
                        v-model="traitId"
                        placeholder="eg: dimethylglycine measurement"
                        @search="handleGetTrait"
                        @focus="handleInputFocusTrait"
                        :options="traitOption"
                    ></t-select>
                    <!--                    <p class="eg">
                        <span>eg:</span>
                        <a href="/trait/Breast%20Cancer">breast cancer</a>
                    </p>-->
                </div>
            </div>
            <div class="searchB">
                <div class="searchN">
                    <span><i class="fas fa-search-location"></i>Search by Gene</span>
                </div>
                <div class="searchSub">
                    <label>
                        <span>Gene Symbol</span>
                    </label>
                    <t-select
                        :disabled="searchDisabled.symbol"
                        class="search-select"
                        v-model="geneId"
                        @search="handleGetGeneBySymbol"
                        @focus="handleInputFocusSymbol"
                        placeholder="eg: KIF1B"
                        :options="geneOption"
                    ></t-select>
                    <!--                    <p class="eg">
                        <span>eg:</span>
                        <a href="/trait/Breast%20Cancer">breast cancer</a>
                    </p>-->
                </div>
                <div class="searchSub">
                    <label>
                        <span>Ensembl ID</span>
                    </label>
                    <t-select
                        :disabled="searchDisabled.ensembl"
                        class="search-select"
                        v-model="ensemblId"
                        placeholder="eg: ENSG00000054523"
                        @search="handleGetGeneByEnsg"
                        @focus="handleInputFocusEnsembl"
                        :options="ensemblOption"
                    ></t-select>
                    <!--                    <p class="eg">
                        <span>eg:</span>
                        <a href="/trait/Breast%20Cancer">breast cancer</a>
                    </p>-->
                </div>
                <div class="searchSub">
                    <label>
                        <span>Genomic Location</span>
                    </label>
                    <t-select
                        :disabled="searchDisabled.location"
                        class="search-select"
                        v-model="genomicId"
                        placeholder="eg: chr1: 10210570-10381603"
                        @search="handleGetGeneByLocation"
                        @focus="handleInputFocusLocation"
                        :options="genomicOption"
                    ></t-select>
                    <!--                    <p class="eg">
                        <span>eg:</span>
                        <a href="/trait/Breast%20Cancer">breast cancer</a>
                    </p>-->
                </div>
            </div>
            <div class="searchB">
                <div class="searchN">
                    <span><i class="fas fa-search-location"></i>Search by Variant</span>
                </div>
                <div class="searchSub">
                    <label>
                        <span>Variant</span>
                    </label>
                    <t-select
                        :disabled="searchDisabled.snp"
                        class="search-select"
                        v-model="snpId"
                        placeholder="eg: rs112849259"
                        @search="handleGetSnp"
                        @focus="handleInputFocusSnp"
                        :options="snpOption"
                    ></t-select>
                    <!--                    <p class="eg">
                        <span>eg:</span>
                        <a href="/trait/Breast%20Cancer">breast cancer</a>
                    </p>-->
                </div>
            </div>
            <div class="searchB">
                <div class="searchN">
                    <span><i class="fas fa-search-location"></i>Search by Publication</span>
                </div>
                <div class="searchSub">
                    <label>
                        <span>Publication</span>
                    </label>
                    <t-select
                        :disabled="searchDisabled.publication"
                        class="search-select"
                        v-model="publicationId"
                        placeholder="eg: 34375979"
                        @search="handleGetPublication"
                        @focus="handleInputFocusPublication"
                        :options="publicationOption"
                    ></t-select>
                    <!--                    <p class="eg">
                        <span>eg:</span>
                        <a href="/trait/Breast%20Cancer">breast cancer</a>
                    </p>-->
                </div>
                <div class="searchSub">
                    <label>
                        <span>Title</span>
                    </label>
                    <t-select
                        :disabled="searchDisabled.title"
                        class="search-select"
                        v-model="title"
                        placeholder="eg: Rare variant"
                        @search="handleGetTitle"
                        @focus="handleInputFocusTitle"
                        :options="titleOption"
                    ></t-select>
                </div>
            </div>
            <div class="buttonBox">
                <button type="button" class="searchButSub" @click="goToPage">Submit</button>
                <button type="reset" class="searchButRes" @click="handleClear">Reset</button>
            </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
            <div class="panel panel-default searchT">
                <div class="panel-heading">
                    <span>Search Tips</span>
                </div>
                <div class="panel-body">
                    <p>RAVAR features a sophisticated search utility, enabling users to directly access the RAVAR resources using relevant terms. In the case of a trait, search parameters include reported trait, EFO Ontology Trait and EFO Ontology IDs. For gene-related or variant-related searches, users can utilize the rsid, gene symbol, Ensembl ID, or genomic location for more detailed information. Additionally, a publication can be located through its PubMed ID.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "search",

    data() {
        return {
            traitId: "",
            traitOption: [],
            geneId: "",
            geneOption: [],
            ensemblId: "",
            ensemblOption: [],
            genomicId: "",
            genomicOption: [],
            publicationId: "",
            publicationOption: [],
            snpId: "",
            snpOption: [],
            titleOption: [],

            searchDisabled: {
                trait: false,
                gene: false,
                symbol: false,
                ensembl: false,
                location: false,
                snp: false,
                publication: false,
                title: false,
            },
        };
    },

    methods: {
        // 清除
        handleClear() {
            this.traitId = "";
            this.geneId = "";
            this.ensemblId = "";
            this.genomicId = "";
            this.snpId = "";
            this.publicationId = "";
            this.title = "";
        },

        clearDisabled() {
            Object.keys(this.searchDisabled).forEach((key) => {
                this.searchDisabled[key] = false;
            });
        },

        setNotDisabled(type) {
            this.handleClear();
            this.clearDisabled();
            Object.keys(this.searchDisabled).forEach((key) => {
                if (key === type) return;
                this.searchDisabled[key] = true;
            });
        },

        handleInputFocus() {
            this.handleClear();
        },

        handleInputFocusTrait() {
            this.setNotDisabled("trait");
        },
        handleInputFocusSymbol() {
            this.setNotDisabled("symbol");
        },
        handleInputFocusEnsembl() {
            this.setNotDisabled("ensembl");
        },
        handleInputFocusLocation() {
            this.setNotDisabled("location");
        },
        handleInputFocusSnp() {
            this.setNotDisabled("snp");
        },
        handleInputFocusPublication() {
            this.setNotDisabled("publication");
        },
        handleInputFocusTitle() {
            this.setNotDisabled("title");
        },

        // Trait
        async handleGetTrait(value) {
            this.setNotDisabled("trait");
            const params = {
                keyword: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("trait/search/page", params);
            const data = res.data.records || [];
            this.traitOption = data.map((v) => {
                return {
                    // 用于显示的名称
                    label: v.traitLabel,
                    value: v.id,
                };
            });
        },

        // Gene Symbol
        async handleGetGeneBySymbol(value) {
            const params = {
                symbol: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("gene/page", params);
            const data = res.data.records || [];
            this.geneOption = data.map((v) => {
                return {
                    label: v.symbol,
                    value: v.id,
                };
            });
        },

        // Ensembl ID
        async handleGetGeneByEnsg(value) {
            const params = {
                ensg: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("gene/page", params);
            const data = res.data.records || [];
            this.ensemblOption = data.map((v) => {
                return {
                    label: v.ensgId,
                    value: v.id,
                };
            });
        },

        // Genomic Location
        async handleGetGeneByLocation(value) {
            const params = {
                location: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("gene/page", params);
            const data = res.data.records || [];
            this.genomicOption = data.map((v) => {
                return {
                    label: v.location,
                    value: v.id,
                };
            });
        },

        // Publication
        async handleGetPublication(value) {
            const params = {
                keyword: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("publication/page", params);
            const data = res.data.records || [];
            this.publicationOption = data.map((v) => {
                return {
                    label: v.pmid,
                    value: v.id,
                };
            });
        },

        // Title
        async handleGetTitle(value) {
            const params = {
                title: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("publication/page", params);
            const data = res.data.records || [];
            this.titleOption = data.map((v) => {
                return {
                    label: v.title,
                    value: v.id,
                };
            });
        },

        // snp
        async handleGetSnp(value) {
            const params = {
                snpId: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("snp/search/page", params);
            const data = res.data.records || [];
            this.snpOption = data.map((v) => {
                return {
                    label: v.snpId,
                    value: v.snpId,
                };
            });
        },

        goToPage() {
            let routeMap = {
                traitId: "trait",
                geneId: "genedetail",
                ensemblId: "genedetail",
                genomicId: "genedetail",
                snpId: "snp",
                publicationId: "publication",
            };

            let key = Object.keys(routeMap).filter((key) => this[key])[0];
            if (!key) {
                return;
            }

            this.$router.push({
                name: routeMap[key],
                params: {
                    id: this[key],
                },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.container-search {
    padding: 45px 8% 0;
    .searchB {
        margin-bottom: 50px;
        .searchN {
            margin-bottom: 30px;
            span {
                display: inline-block;
                width: 280px;
                color: #176b6e;
                font-size: 22px;
                font-weight: bold;
            }
        }
    }

    .searchSub {
        margin-bottom: 30px;

        label {
            width: 200px;
            margin-bottom: 0;

            span {
                font-size: 18px;
            }
        }

        .eg {
            margin-top: 10px;
            margin-bottom: 0;
            margin-left: 210px;
        }
    }
}

.search-select {
    width: 370px;
    display: inline-block;
}

.buttonBox {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;

    .searchButSub,
    .searchButRes {
        color: #ffffff;
        background-color: #176b6e;
        font-size: 20px;
        font-weight: bold;
        outline: none;
        border: 0;
    }

    .searchButSub {
        margin-right: 30px;
    }
}

.searchT {
    margin-top: 238px;
    .panel-body {
        p {
            line-height: 28px;
            text-align: justify;
        }
    }
}
</style>
