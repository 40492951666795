<template>
    <div class="container-trait">
        <div class="traTitle">
            <h3>{{ detailInfo.name }}</h3>
        </div>
        <div class="title">Trait information</div>
        <table class="table table-bordered table-hover">
            <tbody>
                <tr>
                    <td>Trait Label</td>
                    <td>{{ detailInfo.traitLabel }}</td>
                </tr>
                <tr>
                    <td>Ontology ID</td>
                    <td>
                        <a v-if="detailInfo.code.includes('EFO:')" :href="`http://www.ebi.ac.uk/efo/${detailInfo.code.replace('EFO:', 'EFO_')}`">
                            <span>{{ detailInfo.code }}</span>
                        </a>
                        <a v-else :href="`http://purl.obolibrary.org/obo/${detailInfo.code.split(':')[0]}_${detailInfo.code.split(':')[1]}`">
                            <span>{{ detailInfo.code }}</span>
                        </a>
                    </td>
                </tr>
                <tr v-if="detailInfo.description != 'skip'">
                    <td>Description</td>
                    <td>{{ detailInfo.description }}</td>
                </tr>
<!--                <tr>-->
<!--                    <td>Trait Type</td>-->
<!--                    <td>{{ detailInfo.type }}</td>-->
<!--                </tr>-->
                <tr>
                    <td>Synonyms</td>
                    <td>{{ detailInfo.sysnonym }}</td>
                </tr>
                <tr>
                    <td>Mapped term(s)</td>
                    <td>{{ detailInfo.efoTree }}</td>
                </tr>
                <tr>
                    <td style="width: 20%">Link to External Recourses</td>
                    <td>
                        <a
                            v-if="detailInfo.code.includes('EFO:')" :href="`http://www.ebi.ac.uk/efo/${detailInfo.code.replace('EFO:', 'EFO_')}`"
                        >
                            <span>EFO</span>
                            <i class="glyphicon glyphicon-share" style="margin-left: 8px"></i>
                        </a>
                        <a
                            v-else :href="`http://purl.obolibrary.org/obo/${detailInfo.code.split(':')[0]}_${detailInfo.code.split(':')[1]}`"
                        >
                            <span>EFO</span>
                            <i class="glyphicon glyphicon-share" style="margin-left: 8px"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="tableT">All publications about this trait</div>

        <div>
            <trait_publication :trait-id="traitId" />
        </div>

        <div class="tableT" style="margin-top: 20px; margin-bottom: 0px;text-align: center">Manhattan Plot (Users can obtain more information about the associations by hovering their mouse over the dots representing each gene)</div>
        <div class="tableT" style="margin-top: 20px; margin-bottom: 20px;text-align: center; color: red; font-size: 20px;">Click the 'Gene' or 'Variant' button (left) to explore detailed information at the gene or variant level.</div>
        <div class="echarts-scatter">
            <div style="width: 100%; height: 100%" ref="scatter"></div>
        </div>

        <div class="tableT">Genes associated with the trait</div>
        <div>
            <trait_gene :trait-id="traitId" />
        </div>

        <div class="tableT" style="margin-top: 20px; margin-bottom: 10px;">Variants associated with the trait</div>
        <div>
            <trait_snp :trait-id="traitId" />
        </div>
    </div>
</template>

<script>
import { traitassoc, traitpub } from "@/mock";
import Trait_publication from "@/views/trait/trait_publication";
import Trait_gene from "@/views/trait/trait_gene";
import Trait_snp from "@/views/trait/trait_snp";

const chrMap = {
    1: 248849517,
    2: 242078722,
    3: 198148238,
    4: 188147743,
    5: 181272307,
    6: 170572870,
    7: 159154430,
    8: 145066685,
    9: 138124624,
    10: 133569835,
    11: 134319564,
    12: 133235877,
    13: 114337626,
    14: 106368436,
    15: 101806887,
    16: 90095932,
    17: 83095122,
    18: 80247514,
    19: 58573575,
    20: 64304820,
    21: 46665124,
    22: 50783667,
};

const zoomOut = 500000;
let xAxisLabel = {};

const chrTotal = Object.values(chrMap).reduce(function (pre, cur, i) {
    xAxisLabel[i + 1] = pre + cur;
    return pre + cur;
}, 0);

function getDotPosX(dot) {
    let posX = xAxisLabel[dot.chr - 1] || 0;
    posX += dot.start;

    return Math.floor(posX / zoomOut);
}

export default {
    name: "trait",
    components: {
        Trait_gene,
        Trait_publication,
        Trait_snp
    },
    data() {
        return {
            traitId: this.$route.params.id,
            traitassoc: traitassoc,
            traitpub: traitpub,
            detailInfo: {
                name: "",
                ontologyId: "",
                synonyms: "",
                type: "",
                description: "",
            },
        };
    },

    created() {
        this.getDetail();
    },

    mounted() {
        this.getEchartsData();
    },

    methods: {

        async getEchartsData() {
            let res = await this.$ajax.get(`trait/${this.traitId}/manhattan/`);
            this.draw(res.data);
        },

        draw(_data) {
            const echarts = this.$echarts.init(this.$refs.scatter);
            let dataTypeMap = {
                Variant: [],
            };

            _data.forEach((v) => {
                v.type = v.type.slice(0, 1).toUpperCase() + v.type.slice(1);
                if (!dataTypeMap[v.type]) {
                    dataTypeMap[v.type] = [];
                }
                dataTypeMap[v.type].push({
                    name: v.type,
                    value: [getDotPosX(v), v.y],
                    _extend: v,
                });
            });

            const dataTypeMapKeyArr = Object.keys(dataTypeMap);
            const data = dataTypeMapKeyArr.map((key) => {
                return {
                    name: key,
                    type: "scatter",
                    symbol: (key === "Gene" && "rect") || null,
                    // 圆的大小
                    symbolSize: 5,
                    // 圆的标签
                    label: {
                        show: true,
                        position: "top",
                        formatter: function (params) {
                            const data = params.data._extend;
                            return data.name;
                        },
                    },
                    data: dataTypeMap[key],
                };
            });

            const option = {
                animation: false,
                grid: {
                    right: 50,
                },
                legend: {
                    show: true,
                    // data: dataTypeMapKeyArr,
                    data: ["Gene", "Variant"],
                    left: "10%",
                    textStyle: {
                        fontSize: 20,
                    },
                },
                tooltip: {
                    show: true,
                    formatter: function (params) {
                        // params.value[0]  x轴 数据
                        // params.value[1]  y轴 数据

                        const results = [];

                        const _extend = params.data._extend;
                        if (_extend.description) {
                            const lines = _extend.description.split("\n");
                            lines.forEach((it) => {
                                const splits = it.split(";");
                                splits.forEach((split) => {
                                    results.push(split);
                                });
                            });
                        }

                        return results.join("<br />");
                    },
                },
                xAxis: {
                    show: true,
                    name: "Genomic Position(number denotes chromosome)",
                    nameGap: 25,
                    nameLocation: "center",
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                    },
                    minInterval: 1,
                    // maxInterval: 1,
                    max: Math.floor((chrTotal + zoomOut) / zoomOut),
                    min: 1,
                    interval: 1,
                    axisLabel: {
                        show: true,
                        formatter: (value) => {
                            let i = Object.values(xAxisLabel)
                                .map((v, _i) => v - chrMap[_i + 1] / 2)
                                .map((v) => Math.floor(v / zoomOut))
                                .indexOf(value);
                            return i + 1 || "";
                        },
                    },
                    splitNumber: 0,
                    nameTextStyle: {
                        fontSize: 15,
                    },
                },
                yAxis: {
                    show: true,
                    name: "-log10 P-value",
                    nameGap: 30,
                    max: 50,
                    nameLocation: "center",
                    nameTextStyle: {
                        fontSize: 15,
                    },
                },
                series: data,
            };

            echarts.setOption(option);
        },

        async getDetail() {
            let res = await this.$ajax.get(`trait/${this.traitId}`);
            this.detailInfo = res.data;
        },
    },
};
</script>

<style lang="less" scoped>
@import "~@/common/style/traDetail";
.search-input {
    display: inline-block;
    width: 200px;
}

.traTitle {
    h3 {
        color: #176b6e;
        padding: 0 15px 0 0;
        display: inline-block;
    }

    a {
        border-radius: 50px;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        line-height: 1.3;
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        background-color: #9b5057;
        text-decoration: underline;
    }
}

.container-trait {
    padding: 0 45px;
}

.table {
    tr {
        td:first-child {
            color: #176b6e;
            width: 150px;
        }
        td {
            line-height: 30px !important;
            font-size: 16px;
            word-break: break-all;
            word-wrap: break-word;
        }
    }
}
.title {
    margin-bottom: 55px;
    font-size: 24px;
    font-weight: bold;
}

.echarts-scatter {
    width: 100%;
    height: 500px;
}
</style>
