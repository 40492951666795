<template>
    <div style="display: flex; padding: 20px;">
        <div class="tree-wrapper">
            <p><b>EFO Ontology Tree</b></p>
            <div class="tree-box">
                <el-tree
                    :expand-on-click-node="false"
                    :data="treeData"
                    node-key="id"
                    :props="{ label: 'name' }"
                    :default-expanded-keys="expandNode"
                    @node-click="handleClickNode"
                />
            </div>
        </div>
        <div style="flex-grow: 1">
            <div class="header-tool">
                <input @input="handleSearchInput" class="form-control search-input" />
                <button-group>
                    <i class="glyphicon glyphicon-download-alt"></i>
                    <template slot="button-list">
<!--                        <li>-->
<!--                            <a @click="handleTableExportCsv">CSV</a>-->
<!--                        </li>-->
<!--                        <li>-->
<!--                            <a @click="handleTableExportTxt">TXT</a>-->
<!--                        </li>-->
                        <li>
                            <a @click="handleExportExcel">MS-Excel</a>
                        </li>
                    </template>
                </button-group>
            </div>
            <t-table
                id="mainTable"
                :data="tableData.data"
                :pageSize="queryTableParams.pageSize"
                :curPage="queryTableParams.pageNum"
                :total="tableData.total"
                @current-change="handleTableCurrentChange"
                @sort-change="handleSortable"
                @size-change="handleTablePageSize"
            >
                <t-table-column label="RID" property="rid" width="10%" sortable>
                    <span slot-scope="{ row }">{{ row.rid }}</span>
                </t-table-column>
                <t-table-column label="Reporeted Trait" property="reported_trait" width="20%"  sortable>
                    <template slot-scope="{ row }">
                        <router-link :to="`/trait/${row.id}`">{{ row.reportedTrait }}</router-link>
                    </template>
                </t-table-column>
                <t-table-column label="EFO Triat Label" property="traitLabel" width="20%" sortable>
                    <template slot-scope="{ row }">
                        <p
                            class="trait"
                            v-for="p in row.type.split(',')"
                            :key="p"
                        >
                            {{ p }}
                        </p>
                    </template>
                    <template slot-scope="{ row }">
                        <router-link :to="`/trait/${row.id}`">{{ row.traitLabel }}</router-link>
                    </template>
                </t-table-column>
                <t-table-column label="EFO Trait Category" property="trait_category" width="12%"  sortable>
                    <template slot-scope="{ row }">
                        <router-link :to="`/trait/${row.id}`">{{ row.traitCategory }} <i class="glyphicon glyphicon-share" style="margin-left: 5px"></i></router-link>
                    </template>
                </t-table-column>
                <t-table-column label="EFO code" property="code" width="10%" sortable>
                    <template slot-scope="{ row }">
                        {{row.code}}
                    </template>
                </t-table-column>

                <!-- <t-table-column label="#Publications" property="publicationCount">
                    <template slot-scope="{ row }">
                        {{ row.publicationCount }}
                        <a style="cursor: pointer" @click="showPublications(row)" v-if="row.publicationCount">
                            <i v-if="row.showPublications" class="glyphicon glyphicon-arrow-up" />
                            <i v-else class="glyphicon glyphicon-arrow-down" />
                        </a>
                    </template>
                    <template slot-scope="{ row }" slot="expand">
                        <tr v-if="row.showPublications">
                            <td colspan="6">
                                <p class="title-p">Reported articles</p>
                                <p>
                                    <router-link v-for="item in (row.publicationList || [])" :to="`publication/${item.id}`" style="margin-right: 10px;">
                                        {{ item.pmid }}
                                    </router-link>
                                </p>
                            </td>
                        </tr>
                    </template>
                </t-table-column> -->
                <t-table-column label="#Publications" property="publication" width="8%" sortable>
                    <span slot-scope="{ row }">{{ row.publication }}</span>
                </t-table-column>
                <t-table-column label="#Associations" property="association" width="10%" sortable>
                    <span slot-scope="{ row }">{{ row.association }}</span>
                </t-table-column>
            </t-table>
        </div>
    </div>
</template>

<script>
import table_mix from "@/common/table_mix";

export default {
    name: "traits",
    mixins: [table_mix],
    data() {
        return {
            treeData: [],
            expandNode: [],
        };
    },

    computed: {
        getTableDataAjaxUrl() {
            return "trait/page";
        },
    },

    created() {
        this.refreshTable();
        this.handleGetTreeData();
    },

    methods: {
        handleExportExcel() {
            let params = this.queryTableParams;
            this.$ajax.download("trait/downloadExcel", params);
        },
        showPublications(row) {
            row.showPublications = !row.showPublications;
        },

        getTableDataAfter(list) {
            list.forEach(v => {
                v.showPublications = false;
            });
        },

        handleClickNode(data) {
            this.queryTableParams.categoryId = data.id || "";
            this.resetRefreshTable();
        },

        async handleGetTreeData() {
            let res = await this.$ajax.get("category/tree");
            (res.data || []).forEach(v => {
                this.expandNode.push(v.id);
            });
            this.expandNode.push("all");
            this.treeData = [{ name: "All", children: res.data, id: "" }];
        },
    },
};
</script>

<style lang="less" scoped>
@import "~@/common/style/traDetail";

.search-input {
    display: inline-block;
    width: 200px;
}

.tree-wrapper {
    margin: 20px 0px 0 0;
}

.tree-box {
    width: 250px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 10px;
    margin-top: 13px;
    max-height: calc(100vh - 260px);
    overflow: auto;

    /deep/ .el-tree-node {
        min-width: fit-content;
    }
}
</style>
