<script>
import tableBody from "./table-body";

export default {
    name: "tTable",
    components: { tableBody },
    props: {
        data: Array,
        pageSize: Number,
        curPage: Number,
        total: Number,
        id: String,
    },

    render(createElement, context) {
        const theadTh = this.showColumns.map((column) => {
            const sortFn = () => this.handleSortable(column);
            let sortableClass = false;
            if (typeof column.curSortable === "boolean" && column.curSortable) {
                sortableClass = "sortable";
            } else if (typeof column.curSortable === "string") {
                sortableClass = column.curSortable;
            }

            const style = {};

            if (sortableClass) {
                style.cursor = "pointer";
            }

            column.width && (style.width = column.width);
            return (
                <th style={style} class={sortableClass} onClick={sortFn}>
                    {column.label}
                </th>
            );
        });

        const bodyTds = this.showColumns.map((column) => {
            const scopedSlots = column.$scopedSlots.default;
            const style = {};
            column.width && (style.width = column.width);
            return function (row) {
                return (
                    <td key={column.property} style={style}>
                        {scopedSlots ? scopedSlots({ row }) : row[column.property]}
                    </td>
                );
            };
        });

        const bodyExpands = this.showColumns
            .filter((column) => column.$scopedSlots.expand)
            .map((column) => {
                const scopedSlots = column.$scopedSlots.expand;
                return function (row) {
                    return scopedSlots({ row });
                };
            });

        const bodyTr = this.data.reduce((pre, data) => {
            pre.push(
                <tr key={JSON.stringify(data)}>
                    {bodyTds.map((column) => {
                        return column(data);
                    })}
                </tr>
            );
            bodyExpands.forEach((column) => {
                pre.push(column(data));
            });

            return pre;
        }, []);

        const pageSize = this.pageSize;
        const total = this.total;
        const offset = total % pageSize && 1;
        const pageItemLength = Math.floor(total / pageSize) + offset;
        this.pageItemLength = pageItemLength;

        let pageItem = [];

        const buildPageItem = (page) => {
            const callback = () => this.handleCurPage(page);
            let classList = ["page-item"];
            page === this.curPage && classList.push("active");
            return (
                <li class={classList} onClick={callback}>
                    <a class="page-link" href="javascript:void(0)">
                        {page}
                    </a>
                </li>
            );
        };

        function buildOmitPageItem() {
            return (
                <li class="page-item page-last-separator disabled">
                    <a class="page-link" href="javascript:void(0)">
                        ...
                    </a>
                </li>
            );
        }

        if (pageItemLength > 7) {
            const curPage = this.curPage;
            if (curPage < 5) {
                let i = 0;
                while (i < 5) {
                    i++;
                    pageItem.push(buildPageItem(i));
                }

                pageItem.push(buildOmitPageItem());
                pageItem.push(buildPageItem(pageItemLength));
            } else if (curPage > pageItemLength - 4) {
                pageItem.push(buildPageItem(1));
                pageItem.push(buildOmitPageItem());

                let i = pageItemLength - 5;
                while (i < pageItemLength) {
                    i++;
                    pageItem.push(buildPageItem(i));
                }
            } else {
                pageItem.push(buildPageItem(1));
                pageItem.push(buildOmitPageItem());
                pageItem.push(buildPageItem(curPage - 1));
                pageItem.push(buildPageItem(curPage));
                pageItem.push(buildPageItem(curPage + 1));
                pageItem.push(buildOmitPageItem());
                pageItem.push(buildPageItem(pageItemLength));
            }
        } else {
            pageItem = new Array(pageItemLength).fill(1).map((_, pageItem) => {
                return buildPageItem(pageItem + 1);
            });
        }

        return (
            <div class="bootstrap-table">
                <div class="fixed-table-container">
                    <div class="fixed-table-body">
                        <table
                            id={this.id}
                            class="t-table table table-hover table-bordered responsive no-wrap"
                        >
                            <thead>
                                <tr>{theadTh}</tr>
                            </thead>
                            <div ref="hiddenColumns" style="display: none">
                                {this.$slots.default}
                            </div>
                            <tbody>{bodyTr}</tbody>
                        </table>
                    </div>
                </div>

                <div class="fixed-table-pagination table-footer">
                    <div>{this.buildPageSizeList()}</div>
                    <div>
                        <ul class="pagination" style="margin: 0; padding: 0;">
                            <li class="page-item page-pre" onClick={this.handlePagePre}>
                                <a class="page-link" href="javascript:void(0)">
                                    ‹
                                </a>
                            </li>
                            {pageItem}
                            <li class="page-item page-next" onClick={this.handlePageNext}>
                                <a class="page-link" href="javascript:void(0)">
                                    ›
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    },

    data() {
        return {
            columns: [],
            pageItemLength: 1,
            showPageSizeMenu: false,
        };
    },

    mounted() {},

    computed: {
        showColumns() {
            return this.columns;
        },
    },

    methods: {
        apiSetColumn(column) {
            this.columns.push(column);
        },

        handleSortable(column) {
            if (!column.curSortable) return;
            console.log(column.curSortable);
            let sortable = "asc";
            if (column.curSortable === "asc") {
                sortable = "desc";
            }
            column.curSortable = sortable;

            this.$emit("sort-change", column.property, sortable);
        },

        handlePagePre() {
            // this.curPage - 1 > 0 && this.curPage--;
            this.curPage - 1 > 0 && this.handleCurPage(this.curPage - 1);
        },

        handlePageNext() {
            // this.curPage + 1 <= this.pageItemLength && this.curPage++;
            this.curPage + 1 <= this.pageItemLength && this.handleCurPage(this.curPage + 1);
        },

        handleCurPage(page) {
            // this.curPage = page;
            this.$emit("current-change", page);
        },

        handleSizeChange(size) {
            // this.pageSize = size;
            this.$emit("size-change", size);
        },

        listenPageSize(el) {
            if (this.$refs.pageSize.contains(el.target)) return;
            this.handleClosePageSizeBtn();
        },

        handleShowPageSizeBtn() {
            this.showPageSizeMenu = true;
            window.addEventListener("click", this.listenPageSize);
        },
        handleClosePageSizeBtn() {
            this.showPageSizeMenu = false;
            window.removeEventListener("click", this.listenPageSize);
        },

        buildPageSizeList() {
            const pageSizeList = [10, 20, 50, 100].map((size) => {
                const callback = (el) => {
                    el.stopPropagation();
                    this.handleSizeChange(size);
                    this.handleClosePageSizeBtn();
                };
                return (
                    <li role="menuitem" onClick={callback}>
                        <a href="javascript:void(0)">{size}</a>
                    </li>
                );
            });

            let menuStyle = {};
            this.showPageSizeMenu && (menuStyle.display = "block");

            return (
                <div
                    class="btn-group"
                    data-toggle="dropdown"
                    onClick={this.handleShowPageSizeBtn}
                    ref="pageSize"
                >
                    <button class="btn btn-default dropdown-toggle" type="button">
                        <span class="page-size">{this.pageSize}</span>
                        <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu" role="menu" style={menuStyle}>
                        {pageSizeList}
                    </ul>
                </div>
            );
        },
    },
};
</script>

<style lang="less" scoped>
.t-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    margin: 20px 0;

    thead {
        background: #f2f2f2;

        th {
            border: 1px solid #dddddd;
            padding: 8px;
            text-align: left;
            color: #176b6e;
            white-space: nowrap;
        }
    }

    tbody {
        td {
            border: 1px solid #dddddd;
            padding: 8px;
            word-break: break-word;
            vertical-align: middle;
        }
    }
}

.table-footer {
    display: flex;
    justify-content: space-between;
}

.sortable {
    background-image: url("./sort_default.png");
    background-position: right;
    background-repeat: no-repeat;
}

.asc {
    background-image: url("./sort_asc.png");
    background-position: right;
    background-repeat: no-repeat;
}

.desc {
    background-image: url("./sort_desc.png");
    background-position: right;
    background-repeat: no-repeat;
}
</style>
