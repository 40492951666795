<template>
    <div>
        <div class="header-tool">
            <input @input="handleSearchInput" class="form-control search-input" />
            <button-group>
                <i class="glyphicon glyphicon-download-alt"></i>
                <template slot="button-list">
<!--                    <li>-->
<!--                        <a @click="handleTableExportCsv">CSV</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <a @click="handleTableExportTxt">TXT</a>-->
<!--                    </li>-->
                    <li>
                        <a @click="handleExportExcel">MS-Excel</a>
                    </li>
                </template>
            </button-group>
        </div>
        <t-table
            id="mainTable"
            :data="tableData.data"
            :pageSize="queryTableParams.pageSize"
            :curPage="queryTableParams.pageNum"
            :total="tableData.total"
            @current-change="handleTableCurrentChange"
            @sort-change="handleSortable"
            @size-change="handleTablePageSize"
        >
            <t-table-column label="Title" property="publicationName" width="16%" />
            <t-table-column label="Reported Trait" property="reportedTrait" width="10%" />
            <t-table-column label="Mapped Trait" property="mappedTrait" width="12%" />
            <t-table-column label="PMID" property="pmid" width="6%" />
            <t-table-column label="Journal" property="journal" width="6%" />
        </t-table>
    </div>
</template>

<script>
import table_mix from "@/common/table_mix";
export default {
    name: "trait_publication",

    mixins: [table_mix],

    props: {
        traitId: String,
    },

    data() {
        return {};
    },

    created() {
        this.refreshTable();
    },

    computed: {
        getTableDataAjaxUrl() {
            return `trait/${this.traitId}/publication/page`;
        },
    },

    methods: {
        handleExportExcel() {
            let params = this.queryTableParams;
            this.$ajax.download(`trait/${this.traitId}/publication/list`, params);
        },
    },
};
</script>

<style scoped></style>
