<template>
    <div class="container-trait">
        <div class="pubTitle">
            <h3>{{ detailInfo.ensgId }}</h3>
        </div>

        <div class="geneT" style="margin-bottom: 55px">Gene information</div>

        <table class="table table-bordered table-hover geneInfor">
            <tbody>
                <tr>
                    <td>Gene Symbol</td>
                    <td>{{ detailInfo.symbol }}</td>
                </tr>
                <tr>
                    <td>Description</td>
                    <td>{{ detailInfo.description }}</td>
                </tr>
                <tr>
                    <td>Ensembl ID</td>
                    <td>
                        <a
                            :href="`http://asia.ensembl.org/Homo_sapiens/Gene/Summary?db=core;g=${detailInfo.ensgId}`"
                        >
                            <span>{{ detailInfo.ensgId }}</span>
                            <i class="glyphicon glyphicon-share" style="margin-left: 8px"></i>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td>Location</td>
                    <td>{{ detailInfo.location }}</td>
                </tr>
                <tr>
                    <td>Gene Type</td>
                    <td>{{ detailInfo.type }}</td>
                </tr>
                <tr>
                    <td>Summary</td>
                    <td>
                        The protein encoded by this gene is a plasma glycoprotein of unknown
                        function. The protein shows sequence similarity to the variable regions of
                        some immunoglobulin supergene family member proteins. [provided by RefSeq,
                        Jul 2008]
                    </td>
                </tr>
                <tr>
                    <td>Link to External Recourses</td>
                    <td>
                        <a
                            :href="`https://www.genecards.org/cgi-bin/carddisp.pl?gene=${detailInfo.ensgId}`"
                        >
                            <span>GeneCards</span>
                            <i class="glyphicon glyphicon-share" style="margin-left: 8px"></i>
                        </a>
                        <a
                            :href="`https://www.ncbi.nlm.nih.gov/gene/?term=${detailInfo.symbol}`"
                        >
                            <span>NCBI</span>
                            <i class="glyphicon glyphicon-share" style="margin-left: 8px"></i>
                        </a>
                        <a
                            :href="`https://asia.ensembl.org/Homo_sapiens/Gene/Summary?db=core;g=${detailInfo.symbol}`"
                        >
                            <span>Ensembl</span>
                            <i class="glyphicon glyphicon-share" style="margin-left: 8px"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="geneT" style="margin-bottom: 55px">Associations about this gene</div>
        <genedetail_trait :detail-id="detailId" />

        <!--
        <div class="geneT">Gene expression levels across GTEx tissues</div>
        <div ref="GTExVContainer" class="GTExVContainer"></div>

        <div class="geneT" style="margin-bottom: 55px">Significant cis-eQTL information about this gene</div>
        <div class="header-tool">
            <input class="form-control search-input">
            <button-group>
                <i class="glyphicon glyphicon-download-alt"></i>
                <template slot="button-list">
                    <li>
                        <a>CSV</a>
                    </li>
                    <li>
                        <a>TXT</a>
                    </li>
                    <li>
                        <a>MS-Excel</a>
                    </li>
                </template>
            </button-group>
        </div>
        <t-table :data="geneeqtl">
            <t-table-column label="Gene Symbol" property="geneSym" width="8%" />
            <t-table-column label="SNP" property="dbsnp" width="8%">
                <a slot-scope="{ row }" :href="`https://www.ncbi.nlm.nih.gov/snp/${row.dbsnp}`">{{ row.dbsnp }}</a>
            </t-table-column>
            <t-table-column label="Ref Allele" property="refallele" width="10%" />
            <t-table-column label="Alt Allele" property="altallele" width="10%" />
            <t-table-column label="SNP Location" property="snpsite" width="10%" />
            <t-table-column label="P-value" property="pvalue" width="10%" />
            <t-table-column label="Tissue" property="tissue" width="20%" />
        </t-table>
        -->
    </div>
</template>

<script>
import { genegtex } from "@/mock/data";
import { geneassoc, geneeqtl } from "@/mock";
import Genedetail_trait from "@/views/genedetail/genedetail_trait";

export default {
    name: "genedetail",
    components: { Genedetail_trait },
    data() {
        return {
            geneassoc: geneassoc,
            geneeqtl: geneeqtl,
            detailId: this.$route.params.id,
            detailInfo: {
                symbol: "",
                location: "",
                description: "",
                ensgId: "",
            },
        };
    },

    created() {
        this.getDetailInfo();
    },

    mounted() {
        // this.GTExView(genegtex);
    },

    methods: {
        async getDetailInfo() {
            let res = await this.$ajax.get(`gene/${this.detailId}`);
            this.detailInfo = res.data;
        },

        GTExView(data) {
            var datagtex = [];
            var legend = [];
            for (var i = 0; i < data.length; i++) {
                var f = false;
                if (legend.indexOf(data[i]["tis"]) === -1) {
                    legend.push(data[i]["tis"]);
                    f = true;
                }
                datagtex.push({
                    y: eval("[" + data[i]["expressionV"] + "]"),
                    type: "box",
                    x0: data[i]["smtsd"],
                    legendgroup: data[i]["tis"],
                    scalegroup: data[i]["tis"],
                    name: data[i]["tis"],
                    boxpoints: false,
                    marker: {
                        color: data[i]["col"],
                    },
                    showlegend: f,
                });
            }
            var layout = {
                xaxis: {
                    title: "Tissues",
                    automargin: true,
                },
                yaxis: {
                    autorange: true,
                    automargin: true,
                    showgrid: true,
                    gridcolor: "rgb(255, 255, 255)",
                    gridwidth: 1,
                    zerolinewidth: 2,
                    title: "",
                },
                margin: {
                    l: 60,
                    r: 60,
                    b: 120,
                    t: 60,
                },
                paper_bgcolor: "rgb(243, 243, 243)",
                plot_bgcolor: "rgb(243, 243, 243)",
                legend: {
                    tracegroupgap: 1,
                },
            };

            var config = {
                displaylogo: false,
                displayModeBar: true,
                responsive: true,
                scrollZoom: true,
                toImageButtonOptions: {
                    filename: "gene_image",
                },
                modeBarButtonsToRemove: [
                    "zoom2d",
                    "resetScale2d",
                    "hoverClosestCartesian",
                    "hoverCompareCartesian",
                ],
            };

            // Plotly.newPlot("GTExVContainer", datagtex, layout, config);
            this.$plotly.newPlot(this.$refs["GTExVContainer"], datagtex, layout, config);
        },
    },
};
</script>

<style lang="less" scoped>
.container-trait {
    padding: 0 45px;

    .GTExVContainer {
        height: 800px;
    }
}

.geneInfor {
    tbody {
        tr {
            td {
                font-size: 16px;
                line-height: 30px !important;
                word-break: break-all;
                word-wrap: break-word;
            }
            td:first-child {
                background-color: #f2f2f2;
                color: #176b6e;
                width: 18%;
            }
        }
    }
}

.geneTitle {
    h3 {
        color: #176b6e;
        padding-right: 15px;
        display: inline-block;
    }
    a {
        border-radius: 50px;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        line-height: 1.3;
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        background-color: #9b5057;
        text-decoration: underline;
    }
}
.geneT {
    font-size: 24px;
    font-weight: bold;
}

.linkB {
    border: none;
    background-color: #ffeddf;
    border-radius: 3px;
}
</style>
