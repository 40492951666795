export default [
    {
        repTra: "Trunk Fat Percentage",
        mapTra: "Body Fat Percentage",
        tissue: "Heart Atrial Appendage",
        geneSym: "A1BG",
        geneId: "ENSG00000121410.11",
        geneTy: "protein-coding",
        method: "PrediXcan",
        zscore: "-5.32E+00",
        pvalue: "1.05E-07",
        stuId: "TWASP000056",
    },
    {
        repTra: "Left Arm Fat Percentage",
        mapTra: "Body Fat Percentage",
        tissue: "Heart Atrial Appendage",
        geneSym: "A1BG",
        geneId: "ENSG00000121410.11",
        geneTy: "protein-coding",
        method: "PrediXcan",
        zscore: "-5.55E+00",
        pvalue: "2.89E-08",
        stuId: "TWASP000056",
    },
    {
        repTra: "Right Arm Fat Percentage",
        mapTra: "Body Fat Percentage",
        tissue: "Heart Atrial Appendage",
        geneSym: "A1BG",
        geneId: "ENSG00000121410.11",
        geneTy: "protein-coding",
        method: "PrediXcan",
        zscore: "-5.47E+00",
        pvalue: "4.49E-08",
        stuId: "TWASP000056",
    },
    {
        repTra: "Body Fat Percentage",
        mapTra: "Body Fat Percentage",
        tissue: "Heart Atrial Appendage",
        geneSym: "A1BG",
        geneId: "ENSG00000121410.11",
        geneTy: "protein-coding",
        method: "PrediXcan",
        zscore: "-5.33E+00",
        pvalue: "9.91E-08",
        stuId: "TWASP000056",
    },
    {
        repTra: "Body Fat Percentage",
        mapTra: "Body Fat Percentage",
        tissue: "Prostate",
        geneSym: "A1BG",
        geneId: "ENSG00000121410.11",
        geneTy: "protein-coding",
        method: "Probabilistic Transcriptome-Wide Association(PTWAS)",
        zscore: "-",
        pvalue: "1.42E-05",
        stuId: "TWASP000141",
    },
];
