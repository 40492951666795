<template>
    <div class="container-trait">
        <div class="geneT" style="margin-bottom: 15px; margin-top: 15px">Variant information</div>

        <table class="table table-bordered table-hover geneInfor">
            <tbody>
                <tr>
                    <td>RSID</td>
                    <td>{{ detailInfo.snpId }}</td>
                </tr>
                <tr>
                    <td>CHR</td>
                    <td>{{ detailInfo.chr }}</td>
                </tr>
                <tr>
                    <td>POS</td>
                    <td>{{ detailInfo.pos }}</td>
                </tr>
                <tr>
                    <td>Mapped Gene</td>
                    <td>

                        <a
                            :href="`https://www.ncbi.nlm.nih.gov/snp/?term=${detailInfo.gene}`"
                        >
                            <span>{{ detailInfo.gene }}</span>
                            <i class="glyphicon glyphicon-share" style="margin-left: 8px"></i>
                        </a>
                    </td>
                </tr>
                <tr>
                    <td>Nearby Gene</td>
                    <td>{{ detailInfo.nearbyGenes }}</td>
                </tr>
                <tr>
                    <td>Genotype </td>
                    <td>{{ detailInfo.genotype  }}</td>
                </tr>
                <tr>
                    <td>Link to External Recourses</td>
                    <td>
                        <a
                            :href="`https://www.ncbi.nlm.nih.gov/snp/?term=${detailInfo.snpId}`"
                        >
                            <span>NCBI</span>
                            <i class="glyphicon glyphicon-share" style="margin-left: 8px"></i>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="geneT" style="margin-bottom: 55px">Associations about this variant</div>
        <snp_gene :detail-id="detailId" />
    </div>
</template>

<script>
import Snp_gene from "@/views/snp/snp_gene";

export default {
    name: "genedetail",
    components: {
        Snp_gene,
    },
    data() {
        return {
            detailId: this.$route.params.id,
            detailInfo: {
                symbol: "",
                location: "",
                description: "",
                ensgId: "",
            },
        };
    },

    created() {
        this.getDetailInfo();
    },

    methods: {
        async getDetailInfo() {
            let res = await this.$ajax.get(`snp/${this.detailId}`);
            this.detailInfo = res.data;
        },
    },
};
</script>

<style lang="less" scoped>
.container-trait {
    padding: 0 45px;

    .GTExVContainer {
        height: 800px;
    }
}

.geneInfor {
    tbody {
        tr {
            td {
                font-size: 16px;
                line-height: 30px !important;
                word-break: break-all;
                word-wrap: break-word;
            }
            td:first-child {
                background-color: #f2f2f2;
                color: #176b6e;
                width: 18%;
            }
        }
    }
}

.geneTitle {
    h3 {
        color: #176b6e;
        padding-right: 15px;
        display: inline-block;
    }
    a {
        border-radius: 50px;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 10px;
        padding: 0 20px;
        line-height: 1.3;
        font-size: 24px;
        font-weight: bold;
        color: #ffffff;
        background-color: #9b5057;
        text-decoration: underline;
    }
}
.geneT {
    font-size: 24px;
    font-weight: bold;
}

.linkB {
    border: none;
    background-color: #ffeddf;
    border-radius: 3px;
}
</style>
