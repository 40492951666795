<template>
    <div class="container-document">
        <div class="menu-list">
            <ul>
                <li v-for="item in contentList" :key="item.title">
                    <a :href="`#${item.title}`">{{ item.title }}</a>
                </li>
            </ul>
        </div>
        <div class="document-content">
            <div class="word-content" v-for="item in contentList" :key="item.title" v-if="item.content">
                <div class="title" :id="item.title">{{ item.title }}</div>
                <div class="content">
                    <img v-if="item.img" :src="item.img" style="width: 100%; height: 400px;" />
                    <p v-for="item in item.content">{{ item }}</p>
                </div>
            </div>

            <div class="echarts-box">
                <div class="echarts-item">
                    <div class="title">Data Statistics</div>
                    <div ref="echarts1" style="width: 100%; height: calc(100% - 30px)" />
                </div>
                <div class="echarts-item">
                    <div class="title">Distribution of Trait Type</div>
                    <div ref="echarts2" style="width: 100%; height: calc(100% - 30px)" />
                </div>
                <div class="echarts-item">
                    <div class="title">Distribution of Gene Type</div>
                    <div ref="echarts3" style="width: 100%; height: calc(100% - 30px)" />
                </div>
                <div class="echarts-item">
                    <div class="title">Top 8 Gene Level Rare Variant Association Software</div>
                    <div ref="echarts4" style="width: 100%; height: calc(100% - 30px)" />
                </div>
            </div>

            <div class="word-content">
                <div class="title">Citation</div>
                <div class="content">
                    <p>If you use the rare variant association at RAVAR, please cite the related literatures:</p>
                    <p>Li Z, Li X, Zhou H, et al. A framework for detecting noncoding rare-variant associations of large-scale whole-genome sequencing studies[J]. Nature Methods, 2022: 1-13.</p>
                    <p>Li X, Quick C, Zhou H, et al. Powerful, scalable and resource-efficient meta-analysis of rare variant associations in large whole genome sequencing studies[J]. Nature genetics, 2023, 55(1): 154-164.</p>
                    <p>Wu M C, Lee S, Cai T, et al. Rare-variant association testing for sequencing data with the sequence kernel association test[J]. The American Journal of Human Genetics, 2011, 89(1): 82-93.</p>
                    <p>Li B, Leal S M. Methods for detecting associations with rare variants for common diseases: application to analysis of sequence data[J]. The American Journal of Human Genetics, 2008, 83(3): 311-321.</p>
                    <p>Povysil G, Petrovski S, Hostyk J, et al. Rare-variant collapsing analyses for complex traits: guidelines and applications[J]. Nature Reviews Genetics, 2019, 20(12): 747-759.</p>
                    <p>Backman J D, Li A H, Marcketta A, et al. Exome sequencing and analysis of 454,787 UK Biobank participants[J]. Nature, 2021, 599(7886): 628-634.</p>
                    <p>Fiziev P P, McRae J, Ulirsch J C, et al. Rare penetrant mutations confer severe risk of common diseases[J]. Science, 2023, 380(6648): eabo1131.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
let contentList = [
    {
        title: "About RAVAR",
        content: [
            "The Rare Variant Association Repository (RAVAR) was established to offer insight into rare variants in human complex traits and diseases. Genome-wide association studies have revealed numerous common variants linked to human disease. However, the contribution of rare variants to common diseases remains relatively unexplored. The UK Biobank provides detailed phenotypic data linked to medical records for around 500,000 participants, presenting an unprecedented opportunity to assess the effect of rare variations across a broad range of traits.",
            "RAVAR includes 95,047 high-quality SNP- or gene-level disease associations, obtained by manually extracting information from 245 publications discovered via literature search.. Rare variants are defined as single nucleotide polymorphisms (SNPs) with a minor allele frequency (MAF) of less than 0.02.",
        ],
    },
    {
        title: "Statistical Data",
        content: [
            "RAVAR contains 76,186 gene-trait associations culled from 192 relevant publications. These publications use various analysis methods and tools, including collapsing analysis (48,529 associations), SKAT (10,768 associations), Burden test (9,733 associations), BOLT-LMM (1,499 associations), SAIGE-GENE+ (1,426 associations), SKAT-O (1,053 associations), STAAR-SKAT (736 associations), aSPU (470 associations), and ACAT-V (467 associations). RAVAR also contains 18,861 SNP-trait associations from 62 relevant publications.",
        ],
    },
    {
        title: "Data Curation",
        content: [
            "We first conducted a literature search on PubMed using predefined keywords, and manually selected publications containing the necessary descriptions. For each qualified publication, we manually curated association information, including reported traits and the method/software used. We only collected significant associations with p-values less than 1E-4 for gene-level or 1E-6 for SNP-level associations, as well as the size of the effect of the SNP on the trait. We reannotated gene and SNP information using the Genome Reference Consortium Human Build 38 patch release 14 (GRCh38.p14). Trait information was standardized by mapping traits to the Experimental Factor Ontology (EFO) database and storing the trait label, ontology ID, description, and synonyms in RAVAR.",
            "Publications included in RAVAR should focus on studying human traits using systematic single-variant or gene-based association testing methods such as SKAT, C-alpha, CMC, VT, aSPU, or other methods. All associations should include p-values to indicate significance, and SNPs should include beta values to indicate the effect size on the trait. Only results with p-values less than 1E-4 for gene-level associations or less than 1E-6 for SNP-level associations will be included.",
        ],
    },
    {
        title: "Data Download",
        content: [
            "All available data on the RAVAR website can be downloaded locally as a tab-separated TXT file. On the 'Download' page, all SNP/gene-level association data are publicly available along with lists summarizing gene information, trait details, and relevant publications.",
        ],
    },
    {
        title: "Interactive plots",
        img: '/images/document.jpeg',
        content: [
            "To better visualize the association between SNPs/genes and diseases, an interactive plot is provided on the RAVAR website. This plot includes all SNPs and genes associated with each curated trait from qualified publications. For each point in the plot, the dot color represents a gene's biotype, while the line size indicates the significance of the association. Users can view all available traits for a given gene or SNP in the interactive plot, or select one trait of interest at a time. Users can also filter associations by gene type, p-value, and MAF.",
        ],
    },
    {
        title: "Citation",
    }
    /*
    {
        title: "Citation",
        content: [
            "If you use the rare variant association at RAVAR, please cite the related literatures:",
            "Li Z, Li X, Zhou H, et al. A framework for detecting noncoding rare-variant associations of large-scale whole-genome sequencing studies[J]. Nature Methods, 2022: 1-13.",
            "Li X, Quick C, Zhou H, et al. Powerful, scalable and resource-efficient meta-analysis of rare variant associations in large whole genome sequencing studies[J]. Nature genetics, 2023, 55(1): 154-164.",
            "Wu M C, Lee S, Cai T, et al. Rare-variant association testing for sequencing data with the sequence kernel association test[J]. The American Journal of Human Genetics, 2011, 89(1): 82-93.",
            "Li B, Leal S M. Methods for detecting associations with rare variants for common diseases: application to analysis of sequence data[J]. The American Journal of Human Genetics, 2008, 83(3): 311-321.",
            "Povysil G, Petrovski S, Hostyk J, et al. Rare-variant collapsing analyses for complex traits: guidelines and applications[J]. Nature Reviews Genetics, 2019, 20(12): 747-759.",
            "Backman J D, Li A H, Marcketta A, et al. Exome sequencing and analysis of 454,787 UK Biobank participants[J]. Nature, 2021, 599(7886): 628-634.",
            "Fiziev P P, McRae J, Ulirsch J C, et al. Rare penetrant mutations confer severe risk of common diseases[J]. Science, 2023, 380(6648): eabo1131.",
        ],
    },+
    */
];

export default {
    name: "document",

    data() {
        return {
            contentList,
        };
    },

    mounted() {
        this.draw1();
        this.draw2();
        this.draw3();
        this.draw4();
    },

    methods: {
        async getEchartsData1() {
            let res = await this.$ajax.get("");
            const data = res.data;
            this.draw1(data);
        },
        async getEchartsData2() {
            let res = await this.$ajax.get("");
            const data = res.data;
            this.draw2(data);
        },
        async getEchartsData3() {
            let res = await this.$ajax.get("");
            const data = res.data;
            this.draw3(data);
        },
        async getEchartsData4() {
            let res = await this.$ajax.get("");
            const data = res.data;
            this.draw4(data);
        },

        draw1(list) {
            list = [
                { name: "2009", value: 2 },
                { name: "2010", value: 9 },
                { name: "2011", value: 10 },
                { name: "2012", value: 13 },
                { name: "2013", value: 21 },
                { name: "2014", value: 33 },
                { name: "2015", value: 59 },
                { name: "2016", value: 91 },
                { name: "2017", value: 126 },
                { name: "2018", value: 149 },
                { name: "2019", value: 168 },
                { name: "2020", value: 186 },
                { name: "2021", value: 209 },
                { name: "2022", value: 237 },
                { name: "2023", value: 245 },
            ];
            const echarts = this.$echarts.init(this.$refs.echarts1);
            const option = {
                tooltip: {
                    show: true,
                },
                xAxis: {
                    show: true,
                    name: "Year",
                    type: "category",
                    nameGap: 25,
                    nameLocation: "center",
                    axisTick: {
                        alignWithLabel: true,
                    },
                    data: list.map((v) => v.name),
                },
                yAxis: {
                    show: true,
                    name: "Number of publications",
                    type: "value",
                    nameGap: 30,
                    nameLocation: "center",
                },
                series: [
                    {
                        data: list.map((v) => v.value),
                        type: "line",
                        smooth: true,
                    },
                ],
            };

            echarts.setOption(option);
        },
        draw2(list) {
            list = [
                { name: "Amino acid measurement", value: 16 },
                { name: "Acid", value: 21 },
                { name: "Organic heterocyclic compound", value: 24 },
                { name: "Cardiovascular measurement", value: 27 },
                { name: "Lipid", value: 53 },
                { name: "Lipid or lipoprotein measurement", value: 179 },
                { name: "Experimental process", value: 55 },
                { name: "Others measurement", value: 127 },
                { name: "Anthropometric measurement", value: 144 },
                { name: "Metabolite measurement", value: 171 },
                { name: "Hematological measurement", value: 228 },
                { name: "Phenotype", value: 369 },
                { name: "Other traits", value: 379 },
                { name: "Protein measurement", value: 753 },
                { name: "Disease", value: 1883 },


                // { name: "Hematopoietic and lymphoid system neoplasm", value: 24 },
                // { name: "Genetic tumor of hematopoietic and lymphoid tissues", value: 19 },
                // { name: "Inborn errors of metabolism", value: 19 },
                // { name: "Rare genetic tumor", value: 19 },
                // { name: "Bone marrow disorder", value: 11 },
                // { name: "Hormone measurement", value: 11 },
                // { name: "Inflammatory bowel disease", value: 10 },
                // { name: "Mental or behavioural disorder biomarker", value: 10 },
                // { name: "Protein", value: 10 },
            ];

            const echarts = this.$echarts.init(this.$refs.echarts2);
            const option = {
                tooltip: {
                    trigger: "item",
                },
                legend: {
                    orient: "vertical",
                    type: "scroll",
                    right: 0,
                },
                series: [
                    {
                        type: "pie",
                        radius: "50%",
                        data: list,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };

            echarts.setOption(option);
        },
        draw3(list) {
            const echarts = this.$echarts.init(this.$refs.echarts3);

            list = [
                { name: "Protein Coding", value: 12705 },
                { name: "lncRNA", value: 121 },
                { name: "Transcribed unprocessed pseudogene", value: 2 },
                { name: "Processed pseudogene", value: 78 },
                { name: "Transcribed unitary pseudogene", value: 10 },
                { name: "Unprocessed pseudogene", value: 2 },
                { name: "Transcribed processed pseudogene", value: 2 },
                { name: "miRNA", value: 5 },
                { name: "snoRNA", value: 2 },
                { name: "ribozyme", value: 1 },
                { name: "ncRNA", value: 4 },
                { name: "misc_RNA", value: 3 },
                { name: "snRNA", value: 3 },
            ];

            const option = {
                tooltip: {
                    trigger: "item",
                },
                legend: {
                    orient: "vertical",
                    right: 0,
                },
                series: [
                    {
                        name: "Access From",
                        type: "pie",
                        radius: "50%",
                        data: list,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };

            echarts.setOption(option);
        },
        draw4(list) {
            list = [
                { name: "Collapsing analyse", value: 48529 },
                { name: "SKAT", value: 10768 },
                { name: "Burden test", value: 9733 },
                { name: "BOLT-LMM", value: 1499 },
                { name: "SAIGE-GENE+", value: 1426 },
                { name: "SKAT-O", value: 1053 },
                { name: "STAAR-SKAT", value: 736 },
                { name: "aSPU", value: 470 },
                { name: "ACAT-V", value: 467 },
            ];

            const echarts = this.$echarts.init(this.$refs.echarts4);
            const option = {
                grid: {
                    right: 150,
                    bottom: 100,
                },
                xAxis: {
                    type: "category",
                    name: "Software",
                    data: list.map((v) => v.name),
                    axisLabel: {
                        rotate: 45,
                    },
                },
                yAxis: {
                    name: "Number of Associations",
                    type: "value",
                },
                series: [
                    {
                        data: list.map((v) => v.value),
                        type: "bar",
                    },
                ],
            };

            echarts.setOption(option);
        },
    },
};
</script>

<style lang="less" scoped>
.container-document {
    position: relative;
    display: flex;
    padding: 30px;
    .menu-list {
        position: sticky;
        top: 20px;
        width: 25%;
        height: min-content;
        background: #f8f8f8;

        color: #176b6e;

        ul {
            font-size: 18px;
            list-style: none;
            padding-left: 20px;
            margin: 0;

            li {
                cursor: pointer;
                padding: 2px 0;

                &:hover {
                    color: #563d7c;
                    font-weight: bold;
                }
            }
        }
    }

    .document-content {
        flex-basis: 100%;

        margin-left: 20px;

        .word-content {
            .title {
                padding-top: 20px;
                padding-bottom: 10px;
                font-size: 26px;
            }

            .content {
                padding-left: 24px;
                font-size: 18px;
            }
        }
    }
}

.echarts-box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .echarts-item {
        width: 100%;
        height: 500px;

        .title {
            font-size: 24px;
        }
    }
}
</style>
