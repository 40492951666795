<template>
    <div style="padding: 20px;">
        <div class="header-tool">
            <input @input="handleSearchInput" class="form-control search-input" />
            <button-group>
                <i class="glyphicon glyphicon-download-alt"></i>
                <template slot="button-list">
<!--                    <li>-->
<!--                        <a @click="handleTableExportCsv">CSV</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <a @click="handleTableExportTxt">TXT</a>-->
<!--                    </li>-->
                    <li>
                        <a @click="handleExportExcel">MS-Excel</a>
                    </li>
                </template>
            </button-group>
        </div>

        <t-table
            id="mainTable"
            :data="tableData.data"
            :pageSize="queryTableParams.pageSize"
            :curPage="queryTableParams.pageNum"
            :total="tableData.total"
            @current-change="handleTableCurrentChange"
            @sort-change="handleSortable"
            @size-change="handleTablePageSize"
        >
            <t-table-column label="RSID" property="snpId" width="6%" sortable>
                <template slot-scope="{ row }">
                    <router-link :to="`/snp/${row.snpId}`">{{ row.snpId }}</router-link>
                </template>
            </t-table-column>
            <t-table-column label="CHR" property="chr" width="6%" sortable/>
            <t-table-column label="POS" property="pos" width="6%" sortable/>
            <!-- <t-table-column label="Reported Trait" property="reportedTrait" width="6%" /> -->
            <!-- <t-table-column label="EFO Code" property="ontologyId" width="6%"  />
            <t-table-column label="EFO Ontology Trait" property="mappedTrait" width="6%" /> -->
            <t-table-column label="Mapped Gene" property="gene" width="6%" sortable/>
            <t-table-column label="Associations" property="associationCount" width="6%" sortable/>
            <!-- <t-table-column label="MAF" property="maf" width="6%" />
            <t-table-column label="Beta" property="beta" width="6%" />
            <t-table-column label="95% CI" property="p95ci" width="6%"  />
            <t-table-column label="P-value" property="pvalue" width="6%"  />
            <t-table-column label="PMID" property="pmid" width="6%" /> -->
        </t-table>
    </div>
</template>

<script>
import table_mix from "@/common/table_mix";

export default {
    name: "publications",
    mixins: [table_mix],

    data() {
        return {};
    },

    computed: {
        getTableDataAjaxUrl() {
            return "snp/page";
        },
    },

    created() {
        this.refreshTable();
    },

    methods: {
        handleExportExcel() {
            let params = this.queryTableParams;
            this.$ajax.download("snp/downloadExcel", params);
        }
    },
};
</script>

<style lang="less" scoped>
.header-tool {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.search-input {
    display: inline-block;
    width: 200px;
}
</style>
