let timer = null;

export default {
    data() {
        return {
            tableData: {
                data: [],
                total: 0,
            },
            queryTableParams: {
                pageSize: 10,
                pageNum: 1,
                keyword: "",
                orderBy: "",
                orderDirection: "",
            },
        };
    },

    computed: {
        getTableDataAjaxUrl() {
            return "";
        },
    },

    methods: {
        handleSearchInput(event) {
            this.queryTableParams.keyword = event.target.value;
            timer = setTimeout(() => {
                clearTimeout(timer);
                this.resetRefreshTable();
            }, 1000 * 1);
        },
        handleTableCurrentChange(curPage) {
            this.queryTableParams.pageNum = curPage;
            this.refreshTable();
        },
        handleTablePageSize(pageSize) {
            this.queryTableParams.pageSize = pageSize;
            this.refreshTable();
        },

        handleTableExportCsv() {
            this.handleTableExport("csv");
            // let params = this.queryTableParams;
            // this.$ajax.download("trait/downloadExcel", params);
        },
        handleTableExportTxt() {
            this.handleTableExport("txt");
        },
        handleTableExportExcel() {
            // let params = this.queryTableParams;
            // this.$ajax.download("trait/downloadExcel", params);
            this.handleTableExport("xls");
        },
        handleTableExport(type) {
            this.$("#mainTable").tableExport({
                type,
            });
        },
        handleSortable(key, sort) {
            this.queryTableParams.orderBy = key;
            this.queryTableParams.orderDirection = sort;
            this.refreshTable();
        },

        resetRefreshTable() {
            this.queryTableParams.pageNum = 1;
            this.refreshTable();
        },
        refreshTable() {
            this.getTableData();
        },
        async getTableData() {
            let params = this.queryTableParams;
            let res = await this.$ajax.get(this.getTableDataAjaxUrl, params);
            const data = res.data;
            this.tableData.total = data.total;
            this.getTableDataAfter(data.records);
            console.log(data.records);
            this.tableData.data = data.records;
        },

        getTableDataAfter() {

        },
    },
};
