<script>
export default {
    name: "tTableColumn",

    render(createElement, context) {
        return (
            <div>{this.$slots.default}</div>
        )
    },

    props: {
        prop: String,
        property: String,
        label: String,
        width: String,
        data: Object,
        sortable: [Boolean, String],
    },

    data() {
        return {
            curSortable: this.sortable,
        };
    },

    created() {
        this.$parent.apiSetColumn(this);
    },

    methods: {},
};
</script>

<style scoped></style>
