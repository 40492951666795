import Axios from "axios";

const axios = Axios.create({
    baseURL: "/api/",
});

export default {
    upload(url, data, option) {
        return axios.post(url, data, {
            headers: {'Content-Type': 'multipart/form-data' }
        }).then((res) => {
            const data = res.data;
            if (data.code === 200) {
                return data;
            }
            throw data;
        });
    },
    post(url, data, option) {
        return axios({
            method: 'POST',
            url,
            data,
            ...option,
        }).then((res) => {
            const data = res.data;
            if (data.code === 200) {
                return data;
            }
            throw data;
        });
    },
    get(url, params, option) {
        return axios({
            url,
            params,
            ...option,
        }).then((res) => {
            const data = res.data;
            if (data.code === 200) {
                return data;
            }
            throw data;
        });
    },
    download(url, params) {
        return axios({
            url,
            params,
            ...{responseType: 'arraybuffer'},

        }).then((res) => {
            if(res.status == '200') {
                // 成功回调
                const exportName = "download.xlsx";
                let blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    navigator.msSaveBlob(blob, exportName);
                } else {
                    const link = document.createElement('a');
                    link.style.display = 'none';
                    var href = URL.createObjectURL(blob);
                    link.href = href;
                    link.setAttribute('download', exportName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(href);
                }
            }
        })
    }
};
