<template>
    <div >
        <div class="col-md-8">
            <div class="container-search" style="width: 100%; margin-bottom: 0px;">
                <div class="searchB">
                    <div class="searchN">
                        <span>We are glad to invite you to contribute to our growing database of rare variant associations. Your submissions can provide invaluable insights and contribute significantly to the understanding of the associations between rare variants and traits. </span>
                    </div>
                </div>
            </div>
            <div class="container-search row" style="padding: 5px 0px 0px 10%; ">
                <p style="font-size: 16px;"> Fields marked with an asterisk (<span style="color: red;">*</span>) are required.</p>
                <div class="col-md-16 offset-md-7" >
                    <el-form :label-position="labelPosition" label-width="200px" :model="form" :rules="rules" ref="ruleForm" >
                        <p style="color: red; font-size: 16px; margin-bottom: 15px;">Please provide the following information about the published study:</p>
                        <el-form-item label="Manuscript Title" prop="title">
                            <el-input v-model="form.title" placeholder="Enter the title of the manuscript here"></el-input>
                        </el-form-item>
                        <el-form-item label="PubMed ID (PMID) " prop="pmid">
                            <el-input v-model="form.pmid" placeholder="Enter the PubMed ID here"></el-input>
                        </el-form-item>
                        <el-form-item label="DOI Number" prop="doi">
                            <el-input v-model="form.doi" placeholder="Enter the DOI number here"></el-input>
                        </el-form-item>
                        <el-form-item label="Authors" prop="authors">
                            <el-input v-model="form.authors" placeholder="Enter the authors' names here"></el-input>
                        </el-form-item>
                        <el-form-item label="Publication Journal" prop="journal">
                            <el-input v-model="form.journal" placeholder="Enter the name of the publication journal here"></el-input>
                        </el-form-item>
                        <el-form-item label="Publication Year" prop="year">
                            <el-input v-model="form.year" placeholder="Enter the year of publication here"></el-input>
                        </el-form-item>
                        <p style="color: red; font-size: 16px; margin-bottom: 15px;">Please provide the following information about the rare variant association:</p>

                        <el-form-item label="Variant" prop="variant">
                            <el-input v-model="form.variant" placeholder="Enter the genetic variant here"></el-input>
                        </el-form-item>
                        <el-form-item label="Gene Symbol" prop="gene">
                            <el-input v-model="form.gene" placeholder="Enter the symbol of the gene here"></el-input>
                        </el-form-item>
                        <el-form-item label="Ensembl ID" prop="ensgId">
                            <el-input v-model="form.ensgId" placeholder="Enter the Ensembl ID here"></el-input>
                        </el-form-item>
                        <el-form-item label="Trait/Disease" prop="trait">
                            <el-input v-model="form.trait" placeholder="Enter the trait or disease associated with the variant here"></el-input>
                        </el-form-item>
                        <el-form-item label="P-value" prop="pvalue">
                            <el-input v-model="form.pvalue" placeholder="Enter the P-value here"></el-input>
                        </el-form-item>
                        <el-form-item label="Association Method (e.g., SKAT)" prop="method">
                            <el-input v-model="form.method" placeholder="Enter the method used for association here"></el-input>
                        </el-form-item>

                        <el-form-item label="RSID" prop="rsid">
                            <el-input v-model="form.rsid" placeholder="Enter the RSID here"></el-input>
                        </el-form-item>
                        <el-form-item label="Minor Allele Frequency (MAF) " prop="maf">
                            <el-input v-model="form.maf" placeholder="Enter the minor allele frequency (MAF) here"></el-input>
                        </el-form-item>
                        <el-form-item label="Your Contact Email" prop="email">
                            <el-input v-model="form.email" placeholder="Enter your email address here. This will be used for any further communication, clarification, or confirmation regarding your submission."></el-input>
                        </el-form-item>
                        <el-form-item label="Upload your Excel File" prop="file">
                            <label for="upload">
                                <input type="file" id="inputFile" name="inputFile" ref="file" style="display:none" @change="onFileChange()" />
                                <input type="text" value="" disabled id="inputFileAgent" />
                                <input type="button" onclick="document.getElementById('inputFile').click()" value="Select file..." />
                            </label>
                        </el-form-item>

                    </el-form>

                    <div>
                        <p>For bulk submissions or if you prefer to submit your data in a customed format, please email the file directly to chencao@njmu.edu.cn.</p>
                        <p>Thank You For Your Contribution!</p>
                        <p>We deeply appreciate the time and effort you've taken to contribute to our database. Your information helps expand our collective knowledge and understanding of rare variant associations. Your submission will undergo a review process, and you will receive a confirmation email once it has been added to the database.</p>
                    </div>

                    <div class="buttonBox">
                        <button type="button" class="searchButSub" @click="e=>handleSubmit('ruleForm')">Submit</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4" style="margin-left: -60px;">
            <div class="container-search" style="width: 100%; margin-bottom: 0px; color: red; text-align: center;">
                <div class="searchB">
                    <div class="searchN">
                        <span>RAVAR Database Feedback</span>
                    </div>
                </div>
            </div>
            <p style="font-weight: bold;">We value your feedback!Please let us know if you encounter any issues, have suggestions for improvements, or just want to share your thoughts about the RAVAR database.</p>
            <el-form :label-position="labelPosition" label-width="85px" :model="feedbackForm" :rules="feedbackRules" ref="feedbackForm" :inline="false">
                <el-form-item label="Email" prop="email">
                    <el-input v-model="feedbackForm.email" placeholder="Enter the email"></el-input>
                </el-form-item>
                <el-form-item label="Type" prop="type">
                    <el-select v-model="feedbackForm.type" placeholder="Please choose feedback type">
                        <el-option key="issueReport" label="Issue Report" value="issueReport" />
                        <el-option key="suggestion" label="Suggestion" value="suggestion" />
                        <el-option key="generalFeedback" label="General" value="general" />
                    </el-select>
                </el-form-item>
                <el-form-item label="Comments" prop="comment">
                    <el-input type="textarea" v-model="feedbackForm.comment" placeholder="Enter comments/feedback"></el-input>
                </el-form-item>
                <div class="buttonBox">
                    <button type="button" class="searchButSub" @click="e=>handleFeedbackSubmit('feedbackForm')">Submit</button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: "search",

    data() {
        return {
            traitId: "",
            traitOption: [],
            geneId: "",
            geneOption: [],
            ensemblId: "",
            ensemblOption: [],
            genomicId: "",
            genomicOption: [],
            publicationId: "",
            publicationOption: [],
            snpId: "",
            snpOption: [],

            searchDisabled: {
                trait: false,
                gene: false,
                symbol: false,
                ensembl: false,
                location: false,
                snp: false,
                publication: false,
            },

            form: {

            },

            feedbackForm: {

            },

            rules: {
                title: [
                    { required: true, message: 'Enter the title of the manuscript here', trigger: 'blur' },
                ],
                pmid: [
                    { required: true, message: 'Enter the PubMed ID here', trigger: 'blur' },
                ],
                doi: [
                    { required: true, message: 'Enter the DOI number here', trigger: 'blur' },
                ],
                authors: [
                    { required: true, message: 'Enter the authors names here', trigger: 'blur' },
                ],
                journal: [
                    { required: true, message: 'Enter the name of the publication journal here', trigger: 'blur' },
                ],
                year: [
                    { required: true, message: 'Enter the year of publication here', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: 'Enter your email address here. This will be used for any further communication, clarification, or confirmation regarding your submission.', trigger: 'blur' },
                ],
            },

            feedbackRules: {
                type: [
                    { required: true, message: 'Choose feedback type', trigger: 'blur' },
                ],
                comment: [
                    { required: true, message: 'Enter the comment', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: 'Enter your email address here. ', trigger: 'blur' },
                ],
            }
        };
    },

    methods: {
        // 清除
        handleClear() {
            this.traitId = "";
            this.geneId = "";
            this.ensemblId = "";
            this.genomicId = "";
            this.snpId = "";
            this.publicationId = "";
        },

        clearDisabled() {
            Object.keys(this.searchDisabled).forEach((key) => {
                this.searchDisabled[key] = false;
            });
        },

        setNotDisabled(type) {
            this.handleClear();
            this.clearDisabled();
            Object.keys(this.searchDisabled).forEach((key) => {
                if (key === type) return;
                this.searchDisabled[key] = true;
            });
        },

        handleInputFocus() {
            this.handleClear();
        },


        handleInputFocusTrait() {
            this.setNotDisabled("trait");
        },
        handleInputFocusSymbol() {
            this.setNotDisabled("symbol");
        },
        handleInputFocusEnsembl() {
            this.setNotDisabled("ensembl");
        },
        handleInputFocusLocation() {
            this.setNotDisabled("location");
        },
        handleInputFocusSnp() {
            this.setNotDisabled("snp");
        },
        handleInputFocusPublication() {
            this.setNotDisabled("publication");
        },

        // Trait
        async handleGetTrait(value) {
            this.setNotDisabled("trait");
            const params = {
                keyword: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("trait/search/page", params);
            const data = res.data.records || [];
            this.traitOption = data.map((v) => {
                return {
                    // 用于显示的名称
                    label: v.traitLabel,
                    value: v.id,
                };
            });
        },

        // Gene Symbol
        async handleGetGeneBySymbol(value) {
            const params = {
                symbol: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("gene/page", params);
            const data = res.data.records || [];
            this.geneOption = data.map((v) => {
                return {
                    label: v.symbol,
                    value: v.id,
                };
            });
        },

        // Ensembl ID
        async handleGetGeneByEnsg(value) {
            const params = {
                ensg: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("gene/page", params);
            const data = res.data.records || [];
            this.ensemblOption = data.map((v) => {
                return {
                    label: v.ensgId,
                    value: v.id,
                };
            });
        },

        // Genomic Location
        async handleGetGeneByLocation(value) {
            const params = {
                location: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("gene/page", params);
            const data = res.data.records || [];
            this.genomicOption = data.map((v) => {
                return {
                    label: v.location,
                    value: v.id,
                };
            });
        },

        // Publication
        async handleGetPublication(value) {
            const params = {
                keyword: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("publication/page", params);
            const data = res.data.records || [];
            this.publicationOption = data.map((v) => {
                return {
                    label: v.pmid,
                    value: v.id,
                };
            });
        },

        // snp
        async handleGetSnp(value) {
            const params = {
                snpId: value,
                pageSize: 20,
            };
            let res = await this.$ajax.get("snp/search/page", params);
            const data = res.data.records || [];
            this.snpOption = data.map((v) => {
                return {
                    label: v.snpId,
                    value: v.snpId,
                };
            });
        },

        async onFileChange() {
            this.file = this.$refs.file.files[0]
            document.getElementById("inputFileAgent").value = document.getElementById("inputFile").value;
        },

        async handleSubmit(formName) {
            const selfForm = this.form
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    console.log('error submit!!');
                    return false;
                }
                debugger

                const formData = new FormData();
                formData.append('form', JSON.stringify(selfForm))

                if (this.file) {
                    formData.append('file', this.file);
                }

                let res = await this.$ajax.upload("/download/gwas/submit", formData);

                this.$message('Submitted successfully!');
            });
        },

        async handleFeedbackSubmit(formName) {
            const selfForm = this.feedbackForm
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    console.log('error submit!!');
                    return false;
                }

                let res = await this.$ajax.post("/download/gwas/feedback", selfForm);

                this.$message('Submitted successfully!');
            });
        }
    },
};
</script>

<style lang="less" scoped>
.container-search {
    padding: 40px 8% 0;
    width: 80%;
    .searchB {
        margin-bottom: 20px;
        .searchN {
            span {
                display: inline-block;
                // width: 280px;
                // color: #176b6e;
                font-size: 16px;
                font-weight: bold;
            }
        }
    }

    .searchSub {
        margin-bottom: 30px;

        label {
            width: 200px;
            margin-bottom: 0;

            span {
                font-size: 18px;
            }
        }

        .eg {
            margin-top: 10px;
            margin-bottom: 0;
            margin-left: 210px;
        }
    }
}

.el-upload__input {
    display: none;
}

input[type=file] {
    display: none;
}

.search-select {
    width: 370px;
    display: inline-block;
}

.buttonBox {
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;

    .searchButSub,
    .searchButRes {
        color: #ffffff;
        background-color: #176b6e;
        font-size: 20px;
        font-weight: bold;
        outline: none;
        border: 0;
    }

    .searchButSub {
        margin-right: 30px;
    }
}

.searchT {
    margin-top: 238px;
    .panel-body {
        p {
            line-height: 28px;
            text-align: justify;
        }
    }
}
</style>
