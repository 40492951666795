<template>
    <div class="container-download">
        <p class="download-title">Download by Trait</p>
        <div style="display: flex; justify-content: center">
            <t-select
                class="search-select"
                :options="downloadOption"
                @search="search"
                placeholder="eg: dimethylglycine measurement"
                v-model="downloadId"
            ></t-select>

            <button class="btn btn-default button-download" @click="handleDownloadFile">
                <i class="glyphicon glyphicon-save"></i>
            </button>
        </div>

        <div style="color: #176b6e; margin-bottom: 5px; margin-top: 50px">
            <span>Available Files</span>
            <i class="glyphicon glyphicon-folder-open" />
        </div>
        <table>
            <thead>
                <tr>
                    <th>Description</th>
                    <th>Details</th>
                    <th>Format</th>
                    <th>Size</th>
                    <th>Download Link</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="file in downloadList">
                    <td>{{ file.description }}</td>
                    <td>{{ file.details }}</td>
                    <td>{{ file.format }}</td>
                    <td>{{ file.size }}</td>
                    <td>
                        <a :href="file.downloadLink" target="_blank">Click to Download</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
const options = new Array(30).fill(1).map((v, i) => {
    return {
        label: i,
        value: i,
    };
});
const downloadList = [
    {
        description: "Publications Information",
        details: "All publications information collected in the RAVAR",
        format: "tab separated file",
        size: "135K",
        downloadUrl: "",
    },
    {
        description: "Gene-level rare variants association",
        details: "All traits ontology information involved in the RAVAR",
        format: "tab separated file",
        size: "1.5M",
        downloadUrl: "",
    },
    {
        description: "Variant-level rare variants association",
        details: "All rare variant-disease associations at gene-level",
        format: "tab separated file",
        size: "33.1M",
        downloadUrl: "",
    },
    {
        description: "",
        details: "All rare variant-disease associations at variant-level",
        format: "tab separated file",
        size: "11.6M",
        downloadUrl: "",
    },
];

export default {
    name: "downloads",

    data() {
        return {
            options,
            downloadList: [],
            downloadId: "",
            downloadOption: [],
        };
    },

    created() {
        this.getDownloadData();
    },

    methods: {
        async search(val) {
            let params = {
                keyword: val,
                pageSize: 20,
            };

            let res = await this.$ajax.get("download/search", params);
            this.downloadOption = (res.data.records || []).map((v) => {
                return {
                    label: v.name,
                    value: v.downloadUrl,
                };
            });
        },

        async getDownloadData() {
            let res = await this.$ajax.get("download/list");
            this.downloadList = res.data || [];
        },

        handleDownloadFile() {
            if (!this.downloadId) return;
            window.open(this.downloadId, "_blank");
        },
    },
};
</script>

<style lang="less" scoped>
.button-download {
    border-color: #176b6e;
    border-radius: 5px;
    padding: 3px 6px;
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    height: 38px;
    color: #176b6e;
    font-size: 24px;
    border-width: 2px;
    margin-left: 10px;
}

.container-download {
    width: 70%;
    margin: 0 auto;
    .download-title {
        font-size: 24px;
        color: #176b6e;
        text-align: center;
    }
}

.search-select {
    width: 100%;
    display: inline-block;
    max-width: 800px;
}

table {
    width: 100%;
    margin: 20px 0 50px;
    border-collapse: collapse;

    thead {
        th {
            padding: 5px 0;
            border-top: 2px solid #000000;
            border-bottom: 2px solid #000000;
            text-align: left;
            height: 50px;
        }
    }

    tbody {
        tr {
            td {
                height: 50px;
            }
        }
        tr:last-child td {
            padding: 10px 0;
            border-bottom: 2px solid #000000;
            text-align: left;
        }
    }

    a {
        color: #176b6e;
    }
}
</style>
