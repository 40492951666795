<template>
    <div class="container-browse">
        <ul class="nav nav-tabs">
            <li
                role="presentation"
                v-for="item in tabList"
                :class="{ active: item.label === tableActive.label }"
                :key="item.label"
                @click="handleTabActive(item)"
            >
                <a href="#">{{ item.label }}</a>
            </li>
        </ul>

        <div style="margin-top: 30px">
            <component :is="showComponent"></component>
        </div>
    </div>
</template>

<script>
import traits from "@/views/browse/traits";
import genes from "@/views/browse/genes";
import publications from "@/views/browse/publications";
import snp from "@/views/browse/snp";

const tabList = [
    {
        label: "Traits",
        component: traits,
    },
    {
        label: "Genes",
        component: genes,
    },
    {
        label: "Publications",
        component: publications,
    },
    {
        label: "Variant",
        component: snp,
    },
];

export default {
    name: "browse",

    data() {
        return {
            tableActive: tabList[0],
            tabList: tabList,
        };
    },

    created() {},

    computed: {
        showComponent() {
            return this.tableActive.component;
        },
    },

    methods: {
        handleTabActive(item) {
            this.tableActive = item;
        },
    },
};
</script>

<style lang="less" scoped>
.container-browse {
    padding: 20px 30px;
}
p {
    margin: 0;
}
.title-p {
    font-weight: bold;
}

.trait-type {
    display: inline-block;
    border-radius: 10px;
    padding: 6px;
    margin-top: 3px;
    margin-bottom: 3px;
}
</style>
