export default [
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs3214663",
        refallele: "GC",
        altallele: "G",
        snpsite: "chr19:58346865",
        effectsize: "0.494755",
        pvalue: "1.61044e-13",
        tissue: "Adipose_Subcutaneous",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs2375470",
        refallele: "A",
        altallele: "G",
        snpsite: "chr19:58353501",
        effectsize: "0.752391",
        pvalue: "8.54538e-11",
        tissue: "Adrenal_Gland",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs3214663",
        refallele: "GC",
        altallele: "G",
        snpsite: "chr19:58346865",
        effectsize: "0.731322",
        pvalue: "5.94657e-11",
        tissue: "Artery_Coronary",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs3214663",
        refallele: "GC",
        altallele: "G",
        snpsite: "chr19:58346865",
        effectsize: "0.690795",
        pvalue: "8.587e-08",
        tissue: "Brain_Anterior_cingulate_cortex_BA24",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs3214663",
        refallele: "GC",
        altallele: "G",
        snpsite: "chr19:58346865",
        effectsize: "0.58743",
        pvalue: "2.01015e-09",
        tissue: "Brain_Caudate_basal_ganglia",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs149208516",
        refallele: "C",
        altallele: "T",
        snpsite: "chr19:58330182",
        effectsize: "0.16448",
        pvalue: "6.18465e-13",
        tissue: "Cells_Cultured_fibroblasts",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs1265960",
        refallele: "G",
        altallele: "A",
        snpsite: "chr19:58348456",
        effectsize: "0.216823",
        pvalue: "3.16012e-09",
        tissue: "Cells_Cultured_fibroblasts",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs6510137",
        refallele: "T",
        altallele: "C",
        snpsite: "chr19:58352784",
        effectsize: "0.593869",
        pvalue: "1.14806e-08",
        tissue: "Esophagus_Gastroesophageal_Junction",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs4801579",
        refallele: "C",
        altallele: "T",
        snpsite: "chr19:58352083",
        effectsize: "0.357379",
        pvalue: "3.04335e-07",
        tissue: "Esophagus_Mucosa",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs1265957",
        refallele: "A",
        altallele: "G",
        snpsite: "chr19:58347310",
        effectsize: "0.578844",
        pvalue: "4.41008e-09",
        tissue: "Esophagus_Muscularis",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs1265957",
        refallele: "A",
        altallele: "G",
        snpsite: "chr19:58347310",
        effectsize: "0.876384",
        pvalue: "1.45493e-20",
        tissue: "Lung",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs11668660",
        refallele: "G",
        altallele: "A",
        snpsite: "chr19:58362218",
        effectsize: "0.183818",
        pvalue: "9.22419e-06",
        tissue: "Lung",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs1265957",
        refallele: "A",
        altallele: "G",
        snpsite: "chr19:58347310",
        effectsize: "0.812442",
        pvalue: "2.88935e-07",
        tissue: "Prostate",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs4801579",
        refallele: "C",
        altallele: "T",
        snpsite: "chr19:58352083",
        effectsize: "0.530027",
        pvalue: "8.47038e-14",
        tissue: "Skin_Not_Sun_Exposed_Suprapubic",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs371567953",
        refallele: "GTCCCC",
        altallele: "G",
        snpsite: "chr19:58358149",
        effectsize: "0.174918",
        pvalue: "1.23359e-06",
        tissue: "Skin_Not_Sun_Exposed_Suprapubic",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs3214663",
        refallele: "GC",
        altallele: "G",
        snpsite: "chr19:58346865",
        effectsize: "0.551991",
        pvalue: "4.16898e-19",
        tissue: "Skin_Sun_Exposed_Lower_leg",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs12609654",
        refallele: "G",
        altallele: "T",
        snpsite: "chr19:58357114",
        effectsize: "0.201394",
        pvalue: "1.07478e-09",
        tissue: "Skin_Sun_Exposed_Lower_leg",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs1265959",
        refallele: "A",
        altallele: "G",
        snpsite: "chr19:58347700",
        effectsize: "0.539689",
        pvalue: "2.47127e-06",
        tissue: "Stomach",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs4801267",
        refallele: "A",
        altallele: "G",
        snpsite: "chr19:58371017",
        effectsize: "0.477136",
        pvalue: "6.2047e-06",
        tissue: "Testis",
    },
    {
        ensmId: "ENSG00000121410.11",
        geneSym: "A1BG",
        dbsnp: "rs1265960",
        refallele: "G",
        altallele: "A",
        snpsite: "chr19:58348456",
        effectsize: "0.93367",
        pvalue: "3.15031e-07",
        tissue: "Uterus",
    },
];
