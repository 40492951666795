<template>
    <div id="app">
        <nav-header></nav-header>
        <router-view />
        <nav-footer></nav-footer>
    </div>
</template>

<script>
import navHeader from "@/components/nav-header";
import NavFooter from "@/components/nav-footer";
export default {
    components: { NavFooter, navHeader },
};
</script>

<style lang="less">

a {
    cursor: pointer;
}
body {
    margin: 0;
    padding: 0;
    color: #333;
}

.tableT {
    font-size: 24px;
    font-weight: bold;
}


.header-tool {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.search-input {
    display: inline-block;
    width: 200px !important;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.table {
    header {
        tr {
            th {
                background-color: #F2F2F2;
                font-size: 18px;
                color: #176B6E;
            }
        }
    }
}

#nav {
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
