<template>
    <div>
        <t-table
            id="mainTable"
            :data="tableData.data"
            :pageSize="queryTableParams.pageSize"
            :curPage="queryTableParams.pageNum"
            :total="tableData.total"
            @current-change="handleTableCurrentChange"
            @sort-change="handleSortable"
            @size-change="handleTablePageSize"
        >
            <t-table-column label="Variant" property="snpId" width="10%" sortable />
            <t-table-column label="CHR" property="chr" width="12%" />
            <t-table-column label="POS" property="pos" width="6%" />
            <t-table-column label="MAF" property="maf" width="12%" />
            <t-table-column label="Mapped Gene" property="gene" width="8%" />
            <t-table-column label="Reported Trait" property="reportedTrait" width="10%" sortable />
            <t-table-column label="EFO Trait Label" property="mappedTrait" width="10%" sortable />
            <t-table-column label="Beta" property="beta" width="10%" />
            <t-table-column label="95% CI" property="p95ci" width="10%" />
            <t-table-column label="P-Value" property="pvalue" width="10%" sortable />
        </t-table>
    </div>
</template>

<script>
import table_mix from "@/common/table_mix";
export default {
    name: "snp_gene",

    mixins: [table_mix],

    props: {
        detailId: String,
    },

    data() {
        return {};
    },

    created() {
        this.refreshTable();
    },

    computed: {
        getTableDataAjaxUrl() {
            return `snp/${this.detailId}/gene/page`;
        },
    },

    methods: {},
};
</script>

<style scoped></style>
