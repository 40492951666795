<template>
    <div
        class="btn-group"
        data-toggle="dropdown"
        @click="handleShowBtnGroup"
        ref="buttonGroup"
    >
        <button class="btn btn-default dropdown-toggle" type="button">
            <span><slot></slot></span>
            <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" role="menu" :style="menuStyle">
            <slot name="button-list"></slot>
        </ul>
    </div>
</template>

<script>
export default {
    name: "btn-group",

    data() {
        return {
            showBtnGroup: false,
        };
    },

    computed: {
        menuStyle() {
            return {
                display: this.showBtnGroup ? "block" : "",
            }
        }
    },

    methods: {
        listenBtnGroup(el) {
            if (this.$refs.buttonGroup.contains(el.target)) return;
            this.handleCloseBtnGroup();
        },

        handleShowBtnGroup() {
            this.showBtnGroup = true;
            window.addEventListener("click", this.listenBtnGroup);
        },

        handleCloseBtnGroup() {
            this.showBtnGroup = false;
            window.removeEventListener("click", this.listenBtnGroup);
        }
    },
};
</script>

<style scoped>

</style>
