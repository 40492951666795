export default {
    render() {
        // console.log(this.$parent.$slots.default);
        return (
            <div>
                {this.$parent.$slots.default}
            </div>
        );
    },
};
