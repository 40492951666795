<template>
    <div class="t-select" ref="tSelect">
        <popper
            ref="popper"
            trigger="clickToOpen"
            :options="{
                placement: 'bottom',
                modifiers: { offset: { offset: '0,0px' } },
            }"
            @show="emitPopperShow"
        >
            <div class="select-input" slot="reference">
                <!--
                <input
                    :value="showInputValue"
                    readonly
                    :placeholder="placeholder"
                    :disabled="disabled"
                    @click="clickInput"
                />
                -->
                <input
                    v-model="searchInputValue"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    @input="handleChangeSearch"
                />
            </div>
            <div class="popper option-box" :style="{ width: `${optionBoxWidth}px` }">
                <div class="search-input-d">
                    <input v-if="0" v-model="searchInputValue" @input="handleChangeSearch" />
                </div>
                <ul>
                    <li
                        v-for="option in showOptions"
                        @click="handleEmit(option)"
                        :key="option.value"
                        :class="{ 'is-active': option.value === inputValue }"
                    >
                        {{ option.label }}
                    </li>
                </ul>
            </div>
        </popper>
    </div>
</template>

<script>
import Popper from "vue-popperjs";
import "vue-popperjs/dist/vue-popper.css";

Popper.methods.appendArrow = () => {};

let timer = null;

export default {
    name: "tSelect",
    components: { Popper },
    props: {
        value: [String, Number],
        options: {
            type: Array,
            default: function () {
                return [];
            },
        },
        placeholder: String,
        disabled: Boolean,
    },

    watch: {
        value(value) {
            this.inputValue = value;
            if (!value) {
                this.searchInputValue = value;
            }
        },
    },

    data() {
        return {
            inputValue: "",
            searchInputValue: "",
            optionBoxWidth: "",
        };
    },

    mounted() {
        this.optionBoxWidth = this.$refs.tSelect.clientWidth;
    },

    computed: {
        showOptions() {
            return this.options;
            return this.options.filter((v) => v.label.toString().includes(this.searchInputValue));
        },
        showInputValue() {
            let val = this.options.find((v) => v.value === this.inputValue) || {};
            return val.label || "";
        },
    },

    methods: {
        clickInput() {
            console.log("sss");
        },
        handleChangeSearch() {
            clearTimeout(timer);
            // 节流
            timer = setTimeout(() => {
                this.$emit("search", this.searchInputValue);
            }, 1000 * 1);
        },
        emitPopperShow() {
            this.$emit("focus");
            this.inputValue = "";
            this.searchInputValue = "";
        },
        handleHidePopper() {
            this.$refs.popper.doClose();
        },

        handleEmit(option) {
            this.$emit("input", option.value);
            this.inputValue = option.value;
            this.searchInputValue = option.label
            this.handleHidePopper();
        },
    },
};
</script>

<style lang="less" scoped>
input {
    outline: 0;
    width: 100%;
    padding-left: 5px;
    box-sizing: border-box;
    border: 2px solid #176b6e;
    height: 100%;
    border-radius: 5px;
}

.t-select {
    height: 38px;
    .popper {
        width: 100%;
    }

    .select-input {
        height: 100%;
        position: relative;
        &::after {
            content: "";
            border-color: #888 transparent transparent transparent;
            border-style: solid;
            border-width: 5px 4px 0 4px;
            height: 0;
            right: 10px;
            margin-left: -4px;
            margin-top: -2px;
            position: absolute;
            top: 50%;
            width: 0;
        }
    }

    .option-box {
        box-sizing: border-box;
        border: 1px solid #aaa;
        background: #ffffff;
        box-shadow: none;

        .search-input-d {
            display: flex;
            input {
                border-width: 1px;
                border-radius: 0;
                height: 30px;
                margin: 2px;
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            max-height: 300px;
            overflow: auto;

            li {
                height: 32px;
                display: flex;
                align-items: center;
                background: #ffffff;
                cursor: pointer;
                padding-left: 5px;
                &.is-active,
                &:hover {
                    color: #ffffff;
                    background: #5897fb;
                }
            }
        }
    }
}
</style>
