<template>
    <div style="padding: 20px;">
        <div class="header-tool">
            <input @input="handleSearchInput" class="form-control search-input" />
            <button-group>
                <i class="glyphicon glyphicon-download-alt"></i>
                <template slot="button-list">
<!--                    <li>-->
<!--                        <a @click="handleTableExportCsv">CSV</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <a @click="handleTableExportTxt">TXT</a>-->
<!--                    </li>-->
                    <li>
                        <a @click="handleExportExcel">MS-Excel</a>
                    </li>
                </template>
            </button-group>
        </div>

        <t-table
            id="mainTable"
            :data="tableData.data"
            :pageSize="queryTableParams.pageSize"
            :curPage="queryTableParams.pageNum"
            :total="tableData.total"
            @current-change="handleTableCurrentChange"
            @sort-change="handleSortable"
            @size-change="handleTablePageSize"
        >
            <t-table-column label="PMID" property="pmid" width="6%" sortable>
                <template slot-scope="{ row }">
                    <router-link :to="`/publication/${row.id}`">{{ row.pmid }}</router-link>
                </template>
            </t-table-column>

            <t-table-column label="PMCID" property="pmcid" width="6%" sortable/>
            <t-table-column label="Title" property="title" width="6%" sortable/>
            <t-table-column label="DOI" property="doi" width="6%" sortable>
                <a slot-scope="{ row }" :href="`https://doi.org/${row.doi}`">{{ row.doi }}</a>
            </t-table-column>
            <t-table-column label="Year" property="year" width="6%" sortable/>
            <t-table-column label="Journal" property="journal" width="6%" />
            <t-table-column label="#Traits" property="traits" width="6%" sortable>
                <template slot-scope="{ row }">
                    <span>{{row.traits}}</span>
                </template>
            </t-table-column>
            <!--
            <t-table-column label="Expression Data Link" property="expreLink" width="6%" />
            -->
        </t-table>
    </div>
</template>

<script>
import table_mix from "@/common/table_mix";

export default {
    name: "publications",
    mixins: [table_mix],

    data() {
        return {};
    },

    computed: {
        getTableDataAjaxUrl() {
            return "publication/page";
        },
    },

    created() {
        this.refreshTable();
    },

    methods: {
        handleExportExcel() {
            let params = this.queryTableParams;
            this.$ajax.download("publication/downloadExcel", params);
        }
    },
};
</script>

<style lang="less" scoped>
.header-tool {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.search-input {
    display: inline-block;
    width: 200px;
}
</style>
