export const publications = [
    {
        stuId: "TWASP000001",
        pmid: "31575856",
        title: "Transcriptome-wide association study of attention deficit hyperactivity disorder identifies associated genes and phenotypes",
        doi: "10.1038/s41467-019-12450-9",
        date: "2019",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;https://www.nimhgenetics.org/resources/commonmind",
    },
    {
        stuId: "TWASP000002",
        pmid: "26854917",
        title: "Integrative approaches for large-scale transcriptome-wide association studies",
        doi: "10.1038/ng.3506",
        date: "2016",
        jour: "Nature Genetics",
        repTraN: "6",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000003",
        pmid: "28238358",
        title: "Integrating gene expression with summary association statistics to identify genes associated with 30 complex traits",
        doi: "10.1016/j.ajhg.2017.01.031",
        date: "2017",
        jour: "American Journal of Human Genetics",
        repTraN: "24",
        expreLink:
            "https://gtexportal.org/;http://gusevlab.org/projects/fusion/;https://www.nimhgenetics.org/resources/commonmind",
    },
    {
        stuId: "TWASP000004",
        pmid: "30545856",
        title: "Transcriptome-wide isoform-level dysregulation in ASD, schizophrenia, and bipolar disorder",
        doi: "10.1126/science.aat8127",
        date: "2018",
        jour: "Science",
        repTraN: "3",
        expreLink: "http://resource.psychencode.org/",
    },
    {
        stuId: "TWASP000005",
        pmid: "29892015",
        title: "Multi-ethnic genome-wide association study for atrial fibrillation",
        doi: "10.1038/s41588-018-0133-9",
        date: "2018",
        jour: "Nature Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000006",
        pmid: "29186694",
        title: "Large-scale cognitive GWAS meta-anlysis reveals tissue-specific neural expression and potential nootropic drug targets",
        doi: "10.1016/j.celrep.2017.11.028",
        date: "2017",
        jour: "Cell Reports",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000007",
        pmid: "30297968",
        title: "Integrative transcriptome analyses of the aging brain implicate altered splicing in Alzheimer's disease susceptibility",
        doi: "10.1038/s41588-018-0238-1",
        date: "2018",
        jour: "Nature Genetics",
        repTraN: "1",
        expreLink: "https://www.radc.rush.edu",
    },
    {
        stuId: "TWASP000008",
        pmid: "30057032",
        title: "An eQTL landscape of kidney tissue in human nephrotic syndrome",
        doi: "10.1016/j.ajhg.2018.07.004",
        date: "2018",
        jour: "American Journal of Human Genetics",
        repTraN: "1",
        expreLink: "https://umich.app.box.com/folder/47806127161",
    },
    {
        stuId: "TWASP000009",
        pmid: "29915430",
        title: "A transcriptome-wide association study of 229,000 women identifies new candidate susceptibility genes for breast cancer",
        doi: "10.1038/s41588-018-0132-x",
        date: "2018",
        jour: "Nature Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000010",
        pmid: "29511167",
        title: "A transcriptome-wide association study identifies PALMD as a susceptibility gene for calcific aortic valve stenosis",
        doi: "10.1038/s41467-018-03260-6",
        date: "2018",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE102249",
    },
    {
        stuId: "TWASP000011",
        pmid: "30578418",
        title: "Trans-ethnic association study of blood pressure determinants in over 750,000 individuals",
        doi: "10.1038/s41588-018-0303-9",
        date: "2019",
        jour: "Nature Genetics",
        repTraN: "3",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000012",
        pmid: "26470795",
        title: "Extensive alterations of the whole-blood transcriptome are associated with body mass index: results of an mRNA profiling study involving two large population-based cohorts",
        doi: "10.1186/s12920-015-0141-x",
        date: "2015",
        jour: "BMC Medical Genomics",
        repTraN: "1",
        expreLink:
            "https://www.ebi.ac.uk/arrayexpress/E-MTAB-1708;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE36382",
    },
    {
        stuId: "TWASP000013",
        pmid: "29202958",
        title: "Transcriptome-wide association study revealed two novel genes associated with nonobstructive azoospermia in a Chinese population",
        doi: "10.1016/j.fertnstert.2017.09.023",
        date: "2017",
        jour: "Fertil Steril",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000014",
        pmid: "29547942",
        title: "Leveraging lung tissue transcriptome to uncover candidate causal genes in COPD genetic associations",
        doi: "10.1093/hmg/ddy091",
        date: "2018",
        jour: "Human Molecular Genetics",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE23546",
    },
    {
        stuId: "TWASP000015",
        pmid: "30287866",
        title: "Large-scale transcriptome-wide association study identifies new prostate cancer risk regions",
        doi: "10.1038/s41467-018-06302-1",
        date: "2019",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org;https://www.synapse.org/CMC;https://www.synapse.org/#!Synapse:syn300013;http://gdac.broadinstitute.org/",
    },
    {
        stuId: "TWASP000016",
        pmid: "30742112",
        title: "Retinal transcriptome and eQTL analyses identify genes associated with age-related macular degeneration",
        doi: "10.1038/s41588-019-0351-9",
        date: "2019",
        jour: "Nature Genetics",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE115828",
    },
    {
        stuId: "TWASP000017",
        pmid: "30054336",
        title: "A transcriptome-wide association study among 97,898 women to identify candidate susceptibility  genes for epithelial ovarian cancer risk",
        doi: "10.1158/0008-5472.CAN-18-0951",
        date: "2018",
        jour: "Cancer Research",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000018",
        pmid: "30636644",
        title: "Genome-wide analysis of genetic predisposition to Alzheimer's disease and related sex disparities",
        doi: "10.1186/s13195-018-0458-8",
        date: "2019",
        jour: "Alzheimer's Research & Therapy",
        repTraN: "1",
        expreLink: "https://yanglab.westlake.edu.cn/software/smr/#DataResource",
    },
    {
        stuId: "TWASP000019",
        pmid: "30333196",
        title: "Cell-type-specific eQTL of primary melanocytes facilitates identification of melanoma susceptibility genes",
        doi: "10.1101/gr.233304.117",
        date: "2018",
        jour: "Genome Research",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE23546",
    },
    {
        stuId: "TWASP000020",
        pmid: "31036433",
        title: "Shared and distinct genetic risk factors for childhood-onset and adult-onset asthma: genome-wide and transcriptome-wide studies",
        doi: "10.1016/S2213-2600(19)30055-4",
        date: "2019",
        jour: "Lancet Respiratory Medicine",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000021",
        pmid: "30824768",
        title: "Prioritizing Parkinson's disease genes using population-scale transcriptomic data",
        doi: "10.1038/s41467-019-08912-9",
        date: "2019",
        jour: "Nature Genetics",
        repTraN: "1",
        expreLink:
            "https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAS00001000411&requestFrom=searchBox;https://www.synapse.org/#!Synapse:syn2759792",
    },
    {
        stuId: "TWASP000022",
        pmid: "31174959",
        title: "Regional heterogeneity in gene expression, regulation, and coherence in the frontal cortex and hippocampus across development and schizophrenia",
        doi: "10.1016/j.neuron.2019.05.013",
        date: "2019",
        jour: "Neuron",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000023",
        pmid: "31043753",
        title: "A transcriptome-wide association study of high-grade serous epithelial ovarian cancer identifies new susceptibility genes and splice variants",
        doi: "10.1038/s41588-019-0395-x",
        date: "2019",
        jour: "Nature Genetics",
        repTraN: "1",
        expreLink: "https://portal.gdc.cancer.gov/",
    },
    {
        stuId: "TWASP000024",
        pmid: "31087417",
        title: "Integrative analysis of dupuytren's disease identifies novel risk locus and reveals a shared genetic etiology with BMI",
        doi: "10.1002/gepi.22209",
        date: "2019",
        jour: "Genetic Epidemiology",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000025",
        pmid: "30666269",
        title: "Prediction of Alzheimer's disease-associated genes by integration of GWAS summary data and expression data",
        doi: "10.3389/fgene.2018.00653",
        date: "2019",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/gap/?term=phs000486.v1.p1;https://www.synapse.org/#!Synapse:syn2759792",
    },
    {
        stuId: "TWASP000026",
        pmid: "30323283",
        title: "Gene expression imputation identifies candidate genes and susceptibility loci associated with cutaneous squamous cell carcinoma",
        doi: "10.1038/s41467-018-06149-6",
        date: "2018",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000027",
        pmid: "30010802",
        title: "Whole blood gene expression associated with clinical biological age",
        doi: "10.1093/gerona/gly164",
        date: "2019",
        jour: "Journals of Gerontology Series A-Biological Sciences and Medical Sciences",
        repTraN: "1",
        expreLink:
            "https://www.framinghamheartstudy.org/fhs-for-researchers/data-available-overview/",
    },
    {
        stuId: "TWASP000028",
        pmid: "30561910",
        title: "Integrative analysis of transcriptome-wide association study and mRNA expression profiles identifies candidate genes associated with autism spectrum disorders",
        doi: "10.1002/aur.2048",
        date: "2019",
        jour: "Autism Research",
        repTraN: "1",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000029",
        pmid: "30334361",
        title: "Transcriptome-wide association study identifies multiple genes and pathways associated with pancreatic cancer",
        doi: "10.1002/cam4.1836",
        date: "2018",
        jour: "Cancer Medecine",
        repTraN: "1",
        expreLink: "https://www.oncomine.org/resource/login.html",
    },
    {
        stuId: "TWASP000030",
        pmid: "30984102",
        title: "Identification of shared genes between ischemic stroke and Parkinson's disease using genome-wide association studies",
        doi: "10.3389/fneur.2019.00297",
        date: "2019",
        jour: "Frontiers in Neuroendocrinology",
        repTraN: "2",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE16561;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE58294;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20295",
    },
    {
        stuId: "TWASP000031",
        pmid: "30864331",
        title: "Influence of tissue context on gene prioritization for predicted transcriptome-wide association studies",
        doi: "10.1142/9789813279827_0027",
        date: "2019",
        jour: "Pacific Symposium on Biocomputing",
        repTraN: "6",
        expreLink: "http://predictdb.org/",
    },
    {
        stuId: "TWASP000032",
        pmid: "30692607",
        title: "Prioritizing Crohn's disease genes by integrating association signals with gene expression implicates monocyte subsets",
        doi: "10.1038/s41435-019-0059-y",
        date: "2019",
        jour: "Genes and Immunity",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc =GSE57945",
    },
    {
        stuId: "TWASP000033",
        pmid: "30827500",
        title: "GRIK5 genetically regulated expression associated with eye and vascular phenomes: discovery through iteration among biobanks, electronic health records, and zebrafish",
        doi: "10.1016/j.ajhg.2019.01.017",
        date: "2019",
        jour: "American Journal of Human Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000034",
        pmid: "31392102",
        title: "Integrative analysis of transcriptome-wide association study and gene expression profiling identifies candidate genes associated with stroke",
        doi: "10.7717/peerj.7435",
        date: "2019",
        jour: "PeerJ",
        repTraN: "1",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000035",
        pmid: "31101764",
        title: "Identification of novel susceptibility loci and genes for prostate cancer risk: a transcriptome-wide association study in over 140,000 European descendants",
        doi: "10.1158/0008-5472.CAN-18-3536",
        date: "2019",
        jour: "Cancer Research",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/gap/?term=phs000424.v6.p1",
    },
    {
        stuId: "TWASP000036",
        pmid: "31065785",
        title: "Integrative analysis of transcriptome-wide association study data and mRNA expression profiles identified candidate genes and pathways associated with atrial fibrillation",
        doi: "10.1007/s00380-019-01418-w",
        date: "2019",
        jour: "Heart and Vessels",
        repTraN: "1",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000037",
        pmid: "31009124",
        title: "Integrative analysis of transcriptome-wide association study data and messenger RNA expression profiles identified candidate genes and pathways for inflammatory bowel disease",
        doi: "10.1002/jcb.28744",
        date: "2019",
        jour: "Journal of Cellular Biochemistry",
        repTraN: "2",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc =GSE83687",
    },
    {
        stuId: "TWASP000038",
        pmid: "30993394",
        title: "Integrating transcriptome-wide association study and mRNA expression profiling identifies novel genes associated with bone mineral density",
        doi: "10.1007/s00198-019-04958-z",
        date: "2019",
        jour: "Osteoporos International",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/gap/?term=phs000486.v1.p1",
    },
    {
        stuId: "TWASP000039",
        pmid: "29848177",
        title: "Transcriptome-wide association study identifies susceptibility  loci and genes for age at natural menopause",
        doi: "10.1177/1933719118776788",
        date: "2019",
        jour: "Reproductive Sciences",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000040",
        pmid: "30709929",
        title: "Transcriptome-wide association study identifies new candidate susceptibility  genes for glioma",
        doi: "10.1158/0008-5472.CAN-18-2888",
        date: "2019",
        jour: "Cancer Research",
        repTraN: "1",
        expreLink: "http://predictdb.org/",
    },
    {
        stuId: "TWASP000041",
        pmid: "31420334",
        title: "Genomic and transcriptomic association studies identify 16 novel susceptibility loci for venous thromboembolism",
        doi: "10.1182/blood.2019000435",
        date: "2019",
        jour: "Blood",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000042",
        pmid: "31230729",
        title: "Novel insight into the etiology of autism spectrum disorder gained by integrating expression data with genome-wide association statistics",
        doi: "10.1016/j.biopsych.2019.04.034",
        date: "2019",
        jour: "Biological Psychiatry",
        repTraN: "1",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000043",
        pmid: "31216018",
        title: "MSH3 modifies somatic instability and disease severity in Huntington's and myotonic dystrophy type 1",
        doi: "10.1093/brain/awz115",
        date: "2019",
        jour: "Brain",
        repTraN: "1",
        expreLink: "https://www.nimhgenetics.org/resources/commonmind",
    },
    {
        stuId: "TWASP000044",
        pmid: "31055733",
        title: "Genetic heterogeneity of Alzheimer's disease in subjects with and without hypertension",
        doi: "10.1007/s11357-019-00071-5",
        date: "2019",
        jour: "Geroscience",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE33321;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE49925;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE35846;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE48348;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE17065;https://www.ebi.ac.uk/arrayexpress/experiments/E-TABM-1140/",
    },
    {
        stuId: "TWASP000045",
        pmid: "31393916",
        title: "Genetically regulated gene expression underlies lipid traits in Hispanic cohorts",
        doi: "10.1371/journal.pone.0220827",
        date: "2019",
        jour: "Plos One",
        repTraN: "3",
        expreLink:
            "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE56045",
    },
    {
        stuId: "TWASP000046",
        pmid: "31429796",
        title: "Transcriptome-wide association study of multiple myeloma identifies candidate susceptibility genes",
        doi: "10.1186/s40246-019-0231-5",
        date: "2019",
        jour: "Human Genomics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000047",
        pmid: "30820706",
        title: "Genetic variant predictors of gene expression provide new insight into risk of colorectal cancer",
        doi: "10.1007/s00439-019-01989-8",
        date: "2021",
        jour: "Human Genomics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;https://www.nimhgenetics.org/",
    },
    {
        stuId: "TWASP000048",
        pmid: "32141789",
        title: "Genetic association analyses highlight IL6, ALPL, and NAV1 as 3 new susceptibility  genes underlying calcific aortic valve stenosis",
        doi: "10.1161/CIRCGEN.119.002617",
        date: "2019",
        jour: "Circulation-Genomic and Precision Medicine",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE102249",
    },
    {
        stuId: "TWASP000049",
        pmid: "31451708",
        title: "Mapping eGFR loci to the renal transcriptome and phenome in the VA Million Veteran Program",
        doi: "10.1038/s41467-019-11704-w",
        date: "2019",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink: "https://portal.gdc.cancer.gov/",
    },
    {
        stuId: "TWASP000050",
        pmid: "32302575",
        title: "Genetic control of expression and splicing in developing human brain informs disease mechanisms",
        doi: "10.1016/j.cell.2020.04.003",
        date: "2020",
        jour: "Cell",
        repTraN: "2",
        expreLink:
            "https://gtexportal.org/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000051",
        pmid: "31455417",
        title: "Integrating transcriptome-wide study and mRNA expression profiles yields novel insights into the biological mechanism of chondropathies",
        doi: "10.1186/s13075-019-1978-8",
        date: "2019",
        jour: "Arthritis Research & Therapy",
        repTraN: "1",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000052",
        pmid: "29411426",
        title: "Integrating eQTL data with GWAS summary statistics in pathway-based analysis with application to schizophrenia",
        doi: "10.1002/gepi.22110",
        date: "2018",
        jour: "Genetic Epidemiology",
        repTraN: "1",
        expreLink:
            "http://gusevlab.org/projects/fusion/;http://www.brainspan.org/static/download.html;https://www.cog-genomics.org/plink2",
    },
    {
        stuId: "TWASP000053",
        pmid: "29808603",
        title: "Transcriptome-wide association studies accounting for colocalization using Egger regression",
        doi: "10.1002/gepi.22131",
        date: "2021",
        jour: "Genetic Epidemiology",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/gap/?term=phs000424.v6.p1",
    },
    {
        stuId: "TWASP000054",
        pmid: "29739930",
        title: "Exploring the phenotypic consequences of tissue specific gene expression variation inferred from GWAS summary statistics",
        doi: "10.1038/s41467-018-03621-1",
        date: "2018",
        jour: "Nature Communications",
        repTraN: "46",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000055",
        pmid: "30988301",
        title: "Genome-wide association and transcriptome studies identify target genes and risk loci for breast cancer",
        doi: "10.1038/s41467-018-08053-5",
        date: "2019",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink: "https://www.nimhgenetics.org/;https://gtexportal.org/",
    },
    {
        stuId: "TWASP000056",
        pmid: "30668570",
        title: "Integrating predicted transcriptome from multiple tissues improves association detection",
        doi: "10.1371/journal.pgen.1007889",
        date: "2019",
        jour: "Plos Genetics",
        repTraN: "127",
        expreLink: "https://www.nimhgenetics.org/;https://gtexportal.org/",
    },
    {
        stuId: "TWASP000057",
        pmid: "30596636",
        title: "Genetic regulation of the placental transcriptome underlies birth weight and risk of childhood obesity",
        doi: "10.1371/journal.pgen.1007799",
        date: "2018",
        jour: "Plos Genetics",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/sra/?term=SRP095910",
    },
    {
        stuId: "TWASP000058",
        pmid: "30096133",
        title: "Genetic architecture of gene expression traits across diverse populations",
        doi: "10.1371/journal.pgen.1007586",
        date: "2018",
        jour: "Plos Genetics",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE56045",
    },
    {
        stuId: "TWASP000059",
        pmid: "29976976",
        title: "Comparison of methods for transcriptome imputation through application to two common complex diseases",
        doi: "10.1038/s41431-018-0176-5",
        date: "2018",
        jour: "European Journal of Human Genetics",
        repTraN: "2",
        expreLink: "https://www.nimhgenetics.org/",
    },
    {
        stuId: "TWASP000060",
        pmid: "29727690",
        title: "A mixed-effects model for powerful association tests in integrative functional genomics",
        doi: "10.1016/j.ajhg.2018.03.019",
        date: "2018",
        jour: "American Journal of Human Genetics",
        repTraN: "1",
        expreLink: "https://www.nimhgenetics.org/",
    },
    {
        stuId: "TWASP000061",
        pmid: "29566699",
        title: "Integrative genomics identifies new genes associated with severe COPD and emphysema",
        doi: "10.1186/s12931-018-0744-9",
        date: "2018",
        jour: "Respiratory Research",
        repTraN: "1",
        expreLink: "https://www.nimhgenetics.org/;https://gtexportal.org/",
    },
    {
        stuId: "TWASP000062",
        pmid: "28362817",
        title: "Cis-eQTL-based trans-ethnic meta-analysis reveals novel genes associated with breast cancer risk",
        doi: "10.1371/journal.pgen.1006690",
        date: "2017",
        jour: "Plos One",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000063",
        pmid: "27625022",
        title: "Peripheral blood transcriptomic signatures of fasting glucose and insulin concentrations",
        doi: "10.2337/db16-0470",
        date: "2016",
        jour: "Diabetes",
        repTraN: "2",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000064",
        pmid: "27019110",
        title: "Integration of summary data from GWAS and eQTL studies predicts complex trait gene targets",
        doi: "10.1038/ng.3538",
        date: "2021",
        jour: "Nature Genetics",
        repTraN: "5",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE33321;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE36382;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE47729;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20332;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20142;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE48348;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE17065;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE49925",
    },
    {
        stuId: "TWASP000065",
        pmid: "26258848",
        title: "A gene-based association method for mapping traits using reference transcriptome data",
        doi: "10.1038/ng.3367",
        date: "2021",
        jour: "Nature Genetics",
        repTraN: "5",
        expreLink: "https://www.nimhgenetics.org/",
    },
    {
        stuId: "TWASP000066",
        pmid: "25882670",
        title: "Integrative network analysis reveals molecular mechanisms of blood pressure regulation",
        doi: "10.15252/msb.20145399",
        date: "2015",
        jour: "Molecular Systems Biology",
        repTraN: "3",
        expreLink: "https://www.ncbi.nlm.nih.gov/gap/advanced_search/?TERM=phs000007",
    },
    {
        stuId: "TWASP000067",
        pmid: "32179489",
        title: "Integrating transcriptome-wide association study and copy number variation study identifies candidate genes and pathways for diffuse non-Hodgkin's lymphoma",
        doi: "10.1016/j.cancergen.2020.02.005",
        date: "2020",
        jour: "Cancer Genetics",
        repTraN: "1",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000068",
        pmid: "32100139",
        title: "Evaluating the genetic correlations between left-handedness and mental disorder using linkage disequilibrium score regression and transcriptome-wide association study",
        doi: "10.1007/s00223-019-00640-y",
        date: "2021",
        jour: "Calcified Tissue International",
        repTraN: "3",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000069",
        pmid: "32574393",
        title: "Transcriptome-wide association study for persistent hepatitis B virus infection and related hepatocellular carcinoma",
        doi: "10.1111/liv.14577",
        date: "2020",
        jour: "Liver International",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000070",
        pmid: "31696517",
        title: "Transcriptome-wide association study reveals candidate causal genes for lung cancer",
        doi: "10.1002/ijc.32771",
        date: "2021",
        jour: "Biochemical Genetics",
        repTraN: "4",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000071",
        pmid: "32435465",
        title: "Integration of transcriptome-wide association study and messenger RNA expression profile to identify genes associated with osteoarthritis",
        doi: "10.1302/2046-3758.93.BJR-2019-0137.R1",
        date: "2021",
        jour: "Bone and Joint Research",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000072",
        pmid: "31685286",
        title: "An integrative analysis of transcriptome-wide association study and mRNA expression profile identified candidate genes for attention-deficit/ hyperactivity disorder",
        doi: "10.1016/j.psychres.2019.112639",
        date: "2021",
        jour: "Psychiatry Research",
        repTraN: "1",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000073",
        pmid: "31691811",
        title: "A transcriptome-wide association study implicates specific pre- and post-synaptic abnormalities in schizophrenia",
        doi: "10.1093/hmg/ddz253",
        date: "2020",
        jour: "Human Molecular Genetics",
        repTraN: "1",
        expreLink:
            "https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000074",
        pmid: "32005911",
        title: "A transcriptome-wide association study based on 27 tissues identifies 106 genes potentially relevant for disease pathology in age-related macular degeneration",
        doi: "10.1038/s41598-020-58510-9",
        date: "2020",
        jour: "Scientific Reports",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000075",
        pmid: "32651461",
        title: "Transcriptome-wide association study for restless legs syndrome identifies new susceptibility genes",
        doi: "10.1038/s42003-020-1105-z",
        date: "2020",
        jour: "Communications Biology",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000076",
        pmid: "33058866",
        title: "Identifying novel susceptibility  genes for colorectal cancer risk from a transcriptome-wide association study of 125,478 subjects",
        doi: "10.1053/j.gastro.2020.08.062",
        date: "2020",
        jour: "Gastroenterology",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000077",
        pmid: "33020666",
        title: "A unified framework for joint-tissue transcriptome-wide association and Mendelian randomization analysis",
        doi: "10.1038/s41588-020-0706-2",
        date: "2021",
        jour: "Nature Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000078",
        pmid: "32978944",
        title: "A tissue-specific collaborative mixed model for jointly analyzing multiple tissues in transcriptome-wide association studies",
        doi: "10.1093/nar/gkaa767",
        date: "2020",
        jour: "Nucleic Acids Research",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000079",
        pmid: "32961112",
        title: "Bayesian genome-wide TWAS method to leverage both cis- and trans-eQTL information through summary statistics",
        doi: "10.1016/j.ajhg.2020.08.022",
        date: "2020",
        jour: "American Journal of Human Genetics",
        repTraN: "2",
        expreLink: "https://www.synapse.org/#!Synapse:syn3219045",
    },
    {
        stuId: "TWASP000080",
        pmid: "32907841",
        title: "A transcriptome-wide association study identifies candidate susceptibility  genes for pancreatic cancer risk",
        doi: "10.1158/0008-5472.CAN-20-1353",
        date: "2020",
        jour: "American Association of Cancer Research",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000081",
        pmid: "32818884",
        title: "The integration of transcriptome-wide association study and mRNA expression profiling data to identify candidate genes and gene sets associated with dental caries",
        doi: "10.1016/j.archoralbio.2020.104863",
        date: "2021",
        jour: "Archives of Oral Biology",
        repTraN: "1",
        expreLink:
            "http://www.brainspan.org/static/download.html;https://www.cog-genomics.org/plink2",
    },
    {
        stuId: "TWASP000082",
        pmid: "32541925",
        title: "Discovery of 318 new risk loci for type 2 diabetes and related vascular outcomes among 1.4 million participants in a multi-ethnic meta-analysis",
        doi: "10.1038/s41588-020-0637-y",
        date: "2020",
        jour: "Nature Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;https://portal.gdc.cancer.gov/",
    },
    {
        stuId: "TWASP000083",
        pmid: "32366953",
        title: "Cis-effects on gene expression in the human prenatal brain associated with genetic risk for neuropsychiatric disorders",
        doi: "10.1038/s41380-020-0743-3",
        date: "2020",
        jour: "Nature Molecular Psychiatry",
        repTraN: "5",
        expreLink:
            "https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAS00001003214&requestFrom=searchBox",
    },
    {
        stuId: "TWASP000084",
        pmid: "32358547",
        title: "Genome-wide association study of MRI markers of cerebral small vessel disease in 42,310 participants",
        doi: "10.1038/s41467-020-15932-3",
        date: "2020",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000085",
        pmid: "31917448",
        title: "A transcriptome-wide association study (TWAS) identifies novel candidate susceptibility  genes for pancreatic cancer",
        doi: "10.1093/jnci/djz246",
        date: "2021",
        jour: "Cancer Medicine",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/gap/?term=phs000424.v6.p1",
    },
    {
        stuId: "TWASP000086",
        pmid: "32115800",
        title: "Transcriptome-wide association study of breast cancer risk by estrogen-receptor status",
        doi: "10.1002/gepi.22288",
        date: "2020",
        jour: "Genetic Epidemiology",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000087",
        pmid: "32103259",
        title: "Tissue specific regulation of transcription in endometrium and association with disease",
        doi: "10.1093/humrep/dez279",
        date: "2020",
        jour: "Human Reproduction",
        repTraN: "1",
        expreLink: "http://reproductivegenomics.com.au/shiny/endo_eqtl_rna/",
    },
    {
        stuId: "TWASP000088",
        pmid: "31978332",
        title: "A multi-tissue transcriptome anlysis of human metabolites guides interpretability of associations based on multi-SNP models for gene expression",
        doi: "10.1016/j.ajhg.2020.01.003",
        date: "2020",
        jour: "American Journal of Human Genetics",
        repTraN: "26",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000089",
        pmid: "7015848",
        title: "Integrating DNA sequencing and transcriptomic data for association analyses of low-frequency variants and lipid traits",
        doi: "10.1093/hmg/ddz314",
        date: "2020",
        jour: "Human Molecular Genetics",
        repTraN: "2",
        expreLink: "https://www.ncbi.nlm.nih.gov/gap/?term=phe000002.v7",
    },
    {
        stuId: "TWASP000090",
        pmid: "31341166",
        title: "Mendelian randomization integrating GWAS and eQTL data reveals genetic determinants of complex and clinical traits",
        doi: "10.1038/s41467-019-10936-0",
        date: "2019",
        jour: "Nature Communications",
        repTraN: "40",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000091",
        pmid: "5142122",
        title: "Colocalization of GWAS and eQTL signals detects target genes",
        doi: "10.1016/j.ajhg.2016.10.003",
        date: "2016",
        jour: "American Journal of Human Genetics",
        repTraN: "2",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000092",
        pmid: "30771789",
        title: "Integration of GWAS and brain eQTL identifies FLOT1 as a risk gene for major depressive disorder",
        doi: "10.1038/s41386-019-0345-4",
        date: "2019",
        jour: "Neropsychopharmacology",
        repTraN: "1",
        expreLink: "https://adknowledgeportal.synapse.org/DataAccess/",
    },
    {
        stuId: "TWASP000093",
        pmid: "6346739",
        title: "Integration of summary data from GWAS and eQTL studies identified novel causal BMD genes with functional predictions",
        doi: "10.1016/j.bone.2018.05.012",
        date: "2018",
        jour: "Bone",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE33321;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE36382;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE47729;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20332;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20142;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE48348;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE17065;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE49925;https://wwwdev.ebi.ac.uk/ega/search/site/EGAD00001002671;https://wwwdev.ebi.ac.uk/ega/search/site/EGAD00001002674;https://wwwdev.ebi.ac.uk/ega/search/site/EGAD00001002675",
    },
    {
        stuId: "TWASP000094",
        pmid: "33604698",
        title: "Multi-omics highlights ABO plasma protein as causal risk factor for COVID-19",
        doi: "10.1007/s00439-021-02264-5",
        date: "2021",
        jour: "Human Genetics",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE23546",
    },
    {
        stuId: "TWASP000095",
        pmid: "33516273",
        title: "Convergent lines of evidence support BIN1 as a risk gene of Alzheimer's disease",
        doi: "10.1186/s40246-021-00307-6",
        date: "2021",
        jour: "Human Genomics",
        repTraN: "1",
        expreLink: "https://eqtlgen.org/;http://cnsgenomics.com/software/smr/#Download",
    },
    {
        stuId: "TWASP000096",
        pmid: "33173895",
        title: "Multi-ancestry genetic study in 5,876 patients identifies an association between excitotoxic genes and early outcomes after acute ischemic stroke",
        doi: "10.1101/2020.10.29.20222257",
        date: "2020",
        jour: "MedRxiv",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000097",
        pmid: "32791513",
        title: "Independent replications and integrative analyses confirm TRANK1 as a susceptibility gene for bipolar disorder",
        doi: "10.1038/s41386-020-00788-4",
        date: "2020",
        jour: "Neuropsychopharmacology",
        repTraN: "1",
        expreLink:
            "https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000098",
        pmid: "32684367",
        title: "Refining attention-deficit/hyperactivity disorder and autism spectrum disorder genetic loci by integrating summary data from genome-wide association, gene expression, and DNA methylation studies",
        doi: "10.1016/j.biopsych.2020.05.002",
        date: "2020",
        jour: "Biological Psychiatry",
        repTraN: "2",
        expreLink:
            "https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAS00001003214&requestFrom=searchBox;https://gtexportal.org/;https://www.synapse.org/#!Synapse:syn3388564",
    },
    {
        stuId: "TWASP000099",
        pmid: "32580138",
        title: "Identification of susceptibility variants to benign childhood epilepsy with centro-temporal spikes (BECTS) in Chinese Han population",
        doi: "10.1016/j.ebiom.2020.102840",
        date: "2021",
        jour: "EBioMedicine",
        repTraN: "1",
        expreLink:
            "https://www.med.upenn.edu/cndr/datasharing.html; http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000100",
        pmid: "32425817",
        title: "Integrated analysis of summary statistics to identify pleiotropic genes and pathways for the comorbidity of schizophrenia and cardiometabolic disease",
        doi: "10.3389/fpsyt.2020.00256",
        date: "2020",
        jour: "Frontiers in Psychiatry",
        repTraN: "8",
        expreLink:
            "https://gtexportal.org/;http://cnsgenomics.com/software/smr/#Download;http://gusevlab.org/projects/fusion/;http://labs.med.miami.edu/myers/;http://www.scandb.org/newinterface/about.html",
    },
    {
        stuId: "TWASP000101",
        pmid: "32184385",
        title: "Further confirmation of netrin 1 receptor (DCC) as a depression risk gene via integrations of multi-omics data",
        doi: "10.1038/s41398-020-0777-y",
        date: "2020",
        jour: "Translational Psychiatry",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;https://s3.us-east-2.amazonaws.com/libd-brainseq2/ SupplementaryTable15_eQTL.tar.gz;http://resource.psychencode.org/;https://www.med.upenn.edu/cndr/datasharing.html; http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000102",
        pmid: "32093658",
        title: "Disentangling the genetics of sarcopenia prioritization of NUDT3 and KLF5 as genes for lean mass & HLA-DQB1-AS1 for hand grip strength with the associated enhancing SNPs & a scoring system",
        doi: "10.1186/s12881-020-0977-6",
        date: "2020",
        jour: "BMC Medical Genetics",
        repTraN: "3",
        expreLink: "https://www.tinyurl.com/abinarain",
    },
    {
        stuId: "TWASP000103",
        pmid: "32090785",
        title: "Integrating genome-wide association study and expression quantitative trait loci data identifies NEGR1 as a causal risk gene of major depression disorder",
        doi: "10.1016/j.jad.2019.11.116",
        date: "2021",
        jour: "EBioMedicine",
        repTraN: "2",
        expreLink:
            "https://gtexportal.org/;http://cnsgenomics.com/software/smr/#Download;https://www.synapse.org/CMC;http://eqtl.brainseq.org",
    },
    {
        stuId: "TWASP000104",
        pmid: "32038707",
        title: "Integrating multi-omics data to identify novel disease genes and single-neucleotide polymorphisms",
        doi: "10.3389/fgene.2019.01336",
        date: "2020",
        jour: "Frontiers in Genetics",
        repTraN: "2",
        expreLink: "https://cnsgenomics.com/software/smr/#eQTLsummarydata",
    },
    {
        stuId: "TWASP000105",
        pmid: "31723243",
        title: "The genome-wide risk alleles for psychiatric disorders at 3p21.1 show convergent effects on mRNA expression, cognitive function, and mushroom dendritic spine",
        doi: "10.1038/s41380-019-0592-0",
        date: "2020",
        jour: "Molecular Psychiatry",
        repTraN: "1",
        expreLink: "https://www.synapse.org/#!Synapse:syn12299750",
    },
    {
        stuId: "TWASP000106",
        pmid: "31708967",
        title: "Integrate GWAS, eQTL, and mQTL data to identify Alzheimer's disease-related genes",
        doi: "10.3389/fgene.2019.01021",
        date: "2019",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink: "https://cnsgenomics.com/data/SMR/Brain-eMeta.tar.gz",
    },
    {
        stuId: "TWASP000107",
        pmid: "30740125",
        title: "Identification of Alzheimer's disease-related genes based on data integration method",
        doi: "10.3389/fgene.2018.00703",
        date: "2019",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink: "-",
    },
    {
        stuId: "TWASP000108",
        pmid: "29483533",
        title: "Comprehensive integrative analyses identify GLT8D1 and CSNK2B as schizophrenia risk genes",
        doi: "10.1038/s41467-018-03247-3",
        date: "2018",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink:
            "https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000109",
        pmid: "29476167",
        title: "A novel variant associated with HDL-C levels by modifying DAGLB expression levels: an annotation-based genome-wide association study",
        doi: "10.1038/s41431-018-0108-4",
        date: "2018",
        jour: "European Journal of Human Genetics",
        repTraN: "2",
        expreLink:
            "http://gusevlab.org/projects/fusion/;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE33321;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE36382;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE47729;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20332;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20142;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE48348;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE17065;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE49925",
    },
    {
        stuId: "TWASP000110",
        pmid: "28744461",
        title: "Integrating genome-wide association and eQTLs studies identifies the genes and gene sets associated with diabetes",
        doi: "10.1155/2017/1758636",
        date: "2017",
        jour: "BioMed Research International",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE33321;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE36382;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE47729;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20332;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20142;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE48348;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE17065;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE49925",
    },
    {
        stuId: "TWASP000111",
        pmid: "28639078",
        title: "A genome-wide expression association analysis identifies genes and pathways associated with amyotrophic lateral sclerosis",
        doi: "10.1007/s10571-017-0512-2",
        date: "2017",
        jour: "Cellular and Molecular Neurobiology",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE33321;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE36382;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE47729;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20332;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20142;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE48348;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE17065;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE49925",
    },
    {
        stuId: "TWASP000112",
        pmid: "28552732",
        title: "Integrating genome-wide association study and expression quantitative trait loci data identifies multiple genes and gene set associated with neuroticism",
        doi: "10.1016/j.pnpbp.2017.05.017",
        date: "2017",
        jour: "Progress in Neuropsychopharmacology & Biological Psychiatry",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE33321;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE36382;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE47729;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20332;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20142;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE48348;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE17065;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE49925",
    },
    {
        stuId: "TWASP000113",
        pmid: "27506385",
        title: "Predicting gene targets from integrative analyses of summary data from GWAS and eQTL studies for 28 human complex traits",
        doi: "10.1186/s13073-016-0338-4",
        date: "2016",
        jour: "Genome Medicine",
        repTraN: "16",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE33321;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE36382;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE47729;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20332;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20142;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE48348;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE17065;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE49925",
    },
    {
        stuId: "TWASP000114",
        pmid: "33619490",
        title: "Association of CXCR6 with COVID-19 severity: delineating the host genetic factors in transcriptomic regulation",
        doi: "10.1101/2021.02.17.431554",
        date: "2021",
        jour: "BioRxiv",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000115",
        pmid: "33603775",
        title: "Integrative anlysis of transcriptome-wide association study and mRNA expression profiles identified candidate genes and pathways associated with acute myocardial infarction",
        doi: "10.3389/fgene.2021.616492",
        date: "2021",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc =GSE29532;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc =GSE61144",
    },
    {
        stuId: "TWASP000116",
        pmid: "33588072",
        title: "Genetic predictors of gene expression associated with psychiatric comorbidity in patients with inflammatory bowel disease :a pilot study",
        doi: "10.1016/j.ygeno.2021.02.001",
        date: "2021",
        jour: "Genomics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000117",
        pmid: "33539344",
        title: "Transcriptome-wide transmission disequilibrium analysis identifies novel risk genes for autism spectrum disorder",
        doi: "10.1371/journal.pgen.1009309",
        date: "2021",
        jour: "Plos Genetics",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000118",
        pmid: "33523105",
        title: "Identification of candidate Parkinson disease genes by integrating genome-wide association study, expression, and epigenetic data sets",
        doi: "10.1001/jamaneurol.2020.5257",
        date: "2021",
        jour: "JAMA Neurology",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000119",
        pmid: "33504897",
        title: "Transcriptome-wide Mendelian randomization study prioritising novel tissue-dependent genes for glioma susceptibility",
        doi: "10.1038/s41598-021-82169-5",
        date: "2021",
        jour: "Scientific Reports",
        repTraN: "3",
        expreLink:
            "https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAS00001003214&requestFrom=searchBox;https://gtexportal.org/;https://www.synapse.org/#!Synapse:syn3388564;https://gtexportal.org/",
    },
    {
        stuId: "TWASP000120",
        pmid: "33495110",
        title: "Integrating genomics and transcriptomics: towards deciphering ADHD",
        doi: "10.1016/j.euroneuro.2021.01.002",
        date: "2021",
        jour: "European Neuropsychopharmacology",
        repTraN: "1",
        expreLink:
            "http://cnsgenomics.com/software/smr/#Download;https://gtexportal.org/;http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000121",
        pmid: "33482886",
        title: "Transcriptome-wide association study identifies susceptibility  genes for rheumatoid arthritis",
        doi: "10.1186/s13075-021-02419-9",
        date: "2021",
        jour: "Arthritis Research & Therapy",
        repTraN: "1",
        expreLink: "https://www. ncbi.nlm.nih.gov/sites/GDSbrowser?acc=GDS3794",
    },
    {
        stuId: "TWASP000122",
        pmid: "33472578",
        title: "The transcriptome-wide association search for genes and genetic variants which associate with BMI and gestational weight gain in women with type 1 diabetes",
        doi: "10.1186/s10020-020-00266-z",
        date: "2021",
        jour: "Molecular Medicine",
        repTraN: "2",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000123",
        pmid: "29632383",
        title: "Transcriptome-wide association study of schizophrenia and chromatin activity yields mechanistic disease insights",
        doi: "10.1038/s41588-018-0092-1",
        date: "2021",
        jour: "Nature Genetics",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE75220;http://gusevlab.org/projects/fusion/;https://www.ncbi.nlm.nih.gov/gap/?term=phs000486.v1.p1",
    },
    {
        stuId: "TWASP000124",
        pmid: "33481009",
        title: "Multi-omics analyses of cognitive traits and psychiatric disorders highlights brain-dependent mechanisms",
        doi: "10.1093/hmg/ddab016",
        date: "2021",
        jour: "Human Molecular Genetics",
        repTraN: "4",
        expreLink:
            "https://gtexportal.org/;http://cnsgenomics.com/software/smr/#Download;https://www.synapse.org/CMC;http://eqtl.brainseq.org",
    },
    {
        stuId: "TWASP000125",
        pmid: "33313492",
        title: "Population-matched transcriptome prediction increases TWAS discovery and replication rate",
        doi: "10.1016/j.isci.2020.101850",
        date: "2020",
        jour: "iScience",
        repTraN: "5",
        expreLink: "http://predictdb.org/",
    },
    {
        stuId: "TWASP000126",
        pmid: "33403041",
        title: "New novel non-MHC genes were identified for cervical cancer with an integrative analysis approach of transcriptome-wide association study",
        doi: "10.7150/jca.47918",
        date: "2021",
        jour: "Journal of Cancer",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;https://www.nimhgenetics.org/",
    },
    {
        stuId: "TWASP000127",
        pmid: "33369784",
        title: "Multi-tissue transcriptome-wide association studies",
        doi: "10.1002/gepi.22374",
        date: "2020",
        jour: "Genetic Epidemiology",
        repTraN: "1",
        expreLink:
            "https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAS00000000109&requestFrom=searchBox",
    },
    {
        stuId: "TWASP000128",
        pmid: "33362862",
        title: "Integrative anlysis of transcriptome-wide association study and mRNA expression profiles identifies candidate genes associated with idiopathic pulmonary fibrosis",
        doi: "10.3389/fgene.2020.604324",
        date: "2020",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/gap/?term=phs000486.v1.p1",
    },
    {
        stuId: "TWASP000129",
        pmid: "33340029",
        title: "Functional annotation of genetic associations by transcriptome-wide association analysis provides insights into neutrophil development regulation",
        doi: "10.1038/s42003-020-01527-7",
        date: "2020",
        jour: "Communications Biology",
        repTraN: "1",
        expreLink:
            "https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAD00001002671&requestFrom=searchBox;https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAD00001002674&requestFrom=searchBox;https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAD00001002675&requestFrom=searchBox",
    },
    {
        stuId: "TWASP000130",
        pmid: "33329728",
        title: "Multiple-tissue integrative transcriptome-wide association studies discovered new genes associated with amyotrophic lateral slerosis",
        doi: "10.3389/fgene.2020.587243",
        date: "2020",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000131",
        pmid: "33684137",
        title: "MOSTWAS: multi-omic strategies for transcriptome-wide association studies",
        doi: "10.1371/journal.pgen.1009398",
        date: "2021",
        jour: "Plos Genetics",
        repTraN: "3",
        expreLink: "https://portal.gdc.cancer.gov/;https://www.synapse.org/#!Synapse:syn3219045",
    },
    {
        stuId: "TWASP000132",
        pmid: "33654266",
        title: "Identification of genetic factors influencing metabolic dysregulation and retinal support for MacTel, a retinal disorder",
        doi: "10.1038/s42003-021-01788-w",
        date: "2021",
        jour: "Communications Biology",
        repTraN: "1",
        expreLink:
            "http://resource.psychencode.org/;https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE115828",
    },
    {
        stuId: "TWASP000133",
        pmid: "33637304",
        title: "Gene expression imputation across multiple tissue types provides insight Into the genetic architecture of frontotemporal dementia and its clinical subtypes",
        doi: "10.1016/j.biopsych.2020.12.023",
        date: "2021",
        jour: "Biological Psychiatry",
        repTraN: "4",
        expreLink:
            "http://gusevlab.org/projects/fusion/;https://gtexportal.org/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000134",
        pmid: "33630843",
        title: "Brain hippocampal transcriptome-wide association study and neurobiological pathway anlysis for Alzheimer's disease",
        doi: "10.1371/journal.pgen.1009363",
        date: "2021",
        jour: "Plos Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000135",
        pmid: "33279206",
        title: "Delineating the genetic component of gene expression in major depression",
        doi: "10.1016/j.biopsych.2020.09.010",
        date: "2021",
        jour: "Biological Psychiatry",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;http://www.braineac.org/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000136",
        pmid: "33155823",
        title: "Genetic correlation anlysis and transcriptome-wide association study suggest the overlapped genetic mechanism between gout and attention-deficit hyperactivity disorder",
        doi: "10.1177/0706743720970844",
        date: "2020",
        jour: "Canadian Journal of Psychiatry",
        repTraN: "2",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000137",
        pmid: "33137956",
        title: "Secondary genome-wide association study using novel analytical strategies disentangle genetic components of cleft lip and/or cleft palate in 1",
        doi: "10.3390/genes11111280",
        date: "2020",
        jour: "Genes",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000138",
        pmid: "33137096",
        title: "Leveraging functional annotation to identify genes associated with complex diseases",
        doi: "10.1371/journal.pcbi.1008315",
        date: "2020",
        jour: "Plos Computational Biology",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000139",
        pmid: "33109261",
        title: "The landscape of host genetic factors involved in immune response to common viral infections",
        doi: "10.1186/s13073-020-00790-x",
        date: "2020",
        jour: "Genome Medicine",
        repTraN: "4",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000140",
        pmid: "32986714",
        title: "Multi-ethnic transcriptome-wide association study of prostate cancer",
        doi: "10.1371/journal.pone.0236209",
        date: "2020",
        jour: "Plos One",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000141",
        pmid: "32912253",
        title: "PTWAS: investigating tissue-relevant causal molecular mechanisms of complex traits using probabilistic TWAS analysis",
        doi: "10.1186/s13059-020-02026-y",
        date: "2020",
        jour: "Genome Biology",
        repTraN: "66",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000142",
        pmid: "32898862",
        title: "Gene expression profiles complement the analysis of genomic modifiers of the clinical onset of Huntington disease",
        doi: "10.1093/hmg/ddaa184",
        date: "2020",
        jour: "Human Molecular Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000143",
        pmid: "32827035",
        title: "Gene expression and RNA splicing imputation identifies novel candidate genes associated with osteoporosis",
        doi: "10.1210/clinem/dgaa572",
        date: "2020",
        jour: "The Journal of Clinical Endocrinology & Metabolism",
        repTraN: "2",
        expreLink:
            "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/gap/?term=phs000424.v6.p1;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE41637;https://www.ebi.ac.uk/ena/browser/view/PRJEB3366?show=reads",
    },
    {
        stuId: "TWASP000144",
        pmid: "32581322",
        title: "An integrated genomic and transcriptomic anlysis reveals candidates of susceptibility  genes for Crohn's disease in Japanese populations",
        doi: "10.1038/s41598-020-66951-5",
        date: "2020",
        jour: "Scientific Reports",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000145",
        pmid: "32366958",
        title: "Genome-wide association study of emotional empathy in children",
        doi: "10.1038/s41598-020-62693-6",
        date: "2020",
        jour: "Scientific Reports",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000146",
        pmid: "32088307",
        title: "Transcriptome-wide and differential expression network analyses of childhood asthma in nasal epithelium",
        doi: "10.1016/j.jaci.2020.02.005",
        date: "2020",
        jour: "The Journal of Allergy and Clinical Immunology",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE19187;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE65205;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE104472",
    },
    {
        stuId: "TWASP000147",
        pmid: "31821614",
        title: "Integrating germline and somatic genetics to identify genes associated with lung cancer",
        doi: "10.1002/gepi.22275",
        date: "2021",
        jour: "Genetic Epidemiology",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000148",
        pmid: "30804563",
        title: "A statistical framework for cross-tissue transcriptome-wide association analysis",
        doi: "10.1038/s41588-019-0345-7",
        date: "2021",
        jour: "Nature Genetics",
        repTraN: "2",
        expreLink: "https://gtexportal.org/;http://starnet.mssm.edu/",
    },
    {
        stuId: "TWASP000149",
        pmid: "31699293",
        title: "Convergent evidence for predispositional effects of brain gray matter volume on alcohol consumption",
        doi: "10.1016/j.biopsych.2019.08.029",
        date: "2020",
        jour: "Biological Psychiatry",
        repTraN: "1",
        expreLink:
            "https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking;https://gtexportal.org/",
    },
    {
        stuId: "TWASP000150",
        pmid: "31648709",
        title: "Genome-wide assessment for resting heart rate and shared genetics with cardiometabolic traits and type 2 diabetes",
        doi: "10.1016/j.jacc.2019.08.1055",
        date: "2019",
        jour: "Journal of the American College of Cardiology",
        repTraN: "6",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000151",
        pmid: "31308362",
        title: "Association of imputed prostate cancer transcriptome with disease risk reveals novel mechanisms",
        doi: "10.1038/s41467-019-10808-7",
        date: "2019",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/gap/?term=phs000985.v1.p1",
    },
    {
        stuId: "TWASP000152",
        pmid: "33679896",
        title: "Integrative anlysis of omics data reveals regulatory network of CDK10 in vitiligo risk",
        doi: "10.3389/fgene.2021.634553",
        date: "2021",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE33321;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE36382;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE47729;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20332;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE20142;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE78840;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE48348;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE17065;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE49925",
    },
    {
        stuId: "TWASP000153",
        pmid: "33691763",
        title: "Integrating transcriptome-wide association study and mRNA expression profile identified candidate genes related to hand osteoarthritis",
        doi: "10.1186/s13075-021-02458-2",
        date: "2021",
        jour: "Arthritis Research & Therapy",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE114007",
    },
    {
        stuId: "TWASP000154",
        pmid: "34103634",
        title: "Prioritization of candidate causal genes for asthma in susceptibility loci derived from UK biobank",
        doi: "10.1038/s42003-021-02227-6",
        date: "2021",
        jour: "Communications Biology",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE23546",
    },
    {
        stuId: "TWASP000155",
        pmid: "34071263",
        title: "Deep learning improves pancreatic cancer diagnosis using RNA-based variants",
        doi: "10.3390/cancers13112654",
        date: "2021",
        jour: "Cancers",
        repTraN: "1",
        expreLink:
            "https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAD00001006915&requestFrom=searchBox",
    },
    {
        stuId: "TWASP000156",
        pmid: "34045744",
        title: "Bi-ancestral depression GWAS in the Million Veteran Program and meta-analysis in >1.2 million individuals highlight new therapeutic directions",
        doi: "10.1038/s41593-021-00860-2",
        date: "2021",
        jour: "Nature Neuroscience",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000157",
        pmid: "34021165",
        title: "Transcriptome-wide association study uncovers the role of essential genes in anthracycline-induced cardiotoxicity",
        doi: "10.1038/s41525-021-00199-4",
        date: "2021",
        jour: "Nature Partner Journals Genomic Medicine",
        repTraN: "2",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000158",
        pmid: "34021117",
        title: "Transcriptome-wide association study identifies new susceptibility genes and pathways for depression",
        doi: "10.1038/s41398-021-01411-w",
        date: "2021",
        jour: "Translational Psychiatry",
        repTraN: "1",
        expreLink:
            "http://eqtl.brainseq.org/phase2/;http://gusevlab.org/projects/fusion;http://www.ukbiobank.ac.uk/",
    },
    {
        stuId: "TWASP000159",
        pmid: "34001886",
        title: "Transcriptome-wide association analysis of brain structures yields insights into pleiotropy with complex neuropsychiatric traits",
        doi: "10.1038/s41467-021-23130-y",
        date: "2021",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000160",
        pmid: "33998598",
        title: "Transcriptome-wide association analysis identifies DACH1 as a kidney disease risk gene that contributes to fibrosis",
        doi: "10.1172/JCI141801",
        date: "2021",
        jour: "Journal of Clinical Investigation",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE115828",
    },
    {
        stuId: "TWASP000161",
        pmid: "33947463",
        title: "Multi-tissue neocortical transcriptome-wide association study implicates 8 genes across 6 genomic loci in Alzheimer's disease",
        doi: "10.1186/s13073-021-00890-2",
        date: "2021",
        jour: "Genomic Medicine",
        repTraN: "1",
        expreLink:
            "https://adknowledgeportal.synapse.org/DataAccess/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000162",
        pmid: "33931583",
        title: "UTMOST, a single and cross-tissue TWAS (transcriptome wide association study), reveals new ASD (autism spectrum disorder) associated genes",
        doi: "10.1038/s41398-021-01378-8",
        date: "2021",
        jour: "Translational Psychiatry",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000163",
        pmid: "33910581",
        title: "Shared genetic etiology and causality between body fat percentage and cardiovascular diseases: a large-scale genome-wide cross-trait analysis",
        doi: "10.1186/s12916-021-01972-z",
        date: "2021",
        jour: "BMC Medicine",
        repTraN: "2",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000164",
        pmid: "33901188",
        title: "Tissue specificity-aware TWAS (TSA-TWAS) framework identifies novel associations with metabolic, immunologic, and virologic traits in HIV-positive adults",
        doi: "10.1371/journal.pgen.1009464",
        date: "2021",
        jour: "Plos Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000165",
        pmid: "33831007",
        title: "Leveraging expression from multiple tissues using sparse canonical correlation analysis and aggregate tests improves the power of transcriptome-wide association studies",
        doi: "10.1371/journal.pgen.1008973",
        date: "2021",
        jour: "Plos Genetics",
        repTraN: "10",
        expreLink: "http://twas-hub.org/",
    },
    {
        stuId: "TWASP000166",
        pmid: "33809961",
        title: "An integrative transcriptome-wide analysis of amyotrophic lateral sclerosis for the identification of potential genetic markers and drug candidates",
        doi: "10.3390/ijms22063216",
        date: "2021",
        jour: "International Journal of Molecular Sciences",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000167",
        pmid: "33798195",
        title: "Novel variance-component TWAS method for studying complex human diseases with applications to Alzheimer's dementia",
        doi: "10.1371/journal.pgen.1009482",
        date: "2021",
        jour: "Plos Genetics",
        repTraN: "1",
        expreLink: "https://www.synapse.org/#!Synapse:syn3219045",
    },
    {
        stuId: "TWASP000168",
        pmid: "33773637",
        title: "Genetic basis of lacunar stroke: a pooled analysis of individual patient data and genome-wide association studies",
        doi: "10.1016/S1474-4422(21)00031-4",
        date: "2021",
        jour: "Lancet Neurology",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking;http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000169",
        pmid: "33909040",
        title: "A cross-tissue transcriptome-wide association study identifies novel susceptibility genes for lung cancer in Chinese populations",
        doi: "10.1093/hmg/ddab119",
        date: "2021",
        jour: "Human Molecular Genetics",
        repTraN: "2",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000170",
        pmid: "34520569",
        title: "A transcriptome-wide association study identifies novel candidate susceptibility genes for prostate cancer risk",
        doi: "10.1002/ijc.33808",
        date: "2021",
        jour: "International Journal of Cancer",
        repTraN: "1",
        expreLink:
            "https://gtexportal.org/;http://gusevlab.org/projects/fusion/;http://gusevlab.org/projects/fusion/;https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking;https://www.ncbi.nlm.nih.gov/gap/?term=phe000002.v7",
    },
    {
        stuId: "TWASP000171",
        pmid: "34504106",
        title: "A transcriptome-wide association study identifies susceptibility genes for Parkinson's disease",
        doi: "10.1038/s41531-021-00221-7",
        date: "2021",
        jour: "Nature Partner Journals Parkison's Disease",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/gap/?term=phs000424.v8.p2",
    },
    {
        stuId: "TWASP000172",
        pmid: "34470669",
        title: "A transcriptome-wide association study of Alzheimer's disease using prediction models of relevant tissues identifies novel candidate susceptibility genes",
        doi: "10.1186/s13073-021-00959-y",
        date: "2021",
        jour: "Genome Medicine",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000173",
        pmid: "33959712",
        title: "Defining functional variants associated with Alzheimer's disease in the induced immune response",
        doi: "10.1093/braincomms/fcab083",
        date: "2021",
        jour: "Brain Communication",
        repTraN: "1",
        expreLink:
            "https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAS00000000109&requestFrom=searchBox;https://www.ebi.ac.uk/arrayexpress/experiments/E-MTAB-2232/; https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAS00001000411&requestFrom=searchBox;https://gtexportal.org/",
    },
    {
        stuId: "TWASP000174",
        pmid: "33772305",
        title: "Epigenetic element-based transcriptome-wide association study identifies novel genes for bipolar disorder",
        doi: "10.1093/schbul/sbab023",
        date: "2021",
        jour: "Schizophrenia Bulletin",
        repTraN: "2",
        expreLink: "https://gtexportal.org/;https://www.nimhgenetics.org/",
    },
    {
        stuId: "TWASP000175",
        pmid: "34163523",
        title: "Integrating transcriptome-wide association study and mRNA expression profiling identifies novel genes associated with osteonecrosis of the femoral head",
        doi: "10.3389/fgene.2021.663080",
        date: "2021",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000176",
        pmid: "34650599",
        title: "Transcriptome-wide association study provides insights into the genetic component of gene expression in anxiety",
        doi: "10.3389/fgene.2021.740134",
        date: "2021",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;http://resource.psychencode.org/",
    },
    {
        stuId: "TWASP000177",
        pmid: "33720009",
        title: "Genetic association and causal inference converge on hyperglycaemia as a modifiable factor to improve lung function",
        doi: "10.7554/eLife.63115",
        date: "2021",
        jour: "Elife",
        repTraN: "3",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000178",
        pmid: "34380480",
        title: "Genome-wide association study followed by trans-ancestry meta-analysis identify 17 new risk loci for schizophrenia",
        doi: "10.1186/s12916-021-02039-9",
        date: "2021",
        jour: "BMC Medicine",
        repTraN: "1",
        expreLink:
            "https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking",
    },
    {
        stuId: "TWASP000179",
        pmid: "34409462",
        title: "A transcriptome-wide association study identifies novel susceptibility genes for psoriasis",
        doi: "10.1093/hmg/ddab237",
        date: "2021",
        jour: "Human Molecular Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000180",
        pmid: "33768244",
        title: "Integrative analyses followed by functional characterization reveal TMEM180 as a schizophrenia risk gene",
        doi: "10.1093/schbul/sbab032",
        date: "2021",
        jour: "Schizophrenia Bulletin",
        repTraN: "1",
        expreLink: "https://www.ebi.ac.uk/arrayexpress/E-MTAB-264",
    },
    {
        stuId: "TWASP000181",
        pmid: "34626721",
        title: "Integrative analysis of transcriptome-wide association study and mRNA expression profile identified candidate genes and pathways associated with aortic aneurysm and dissection",
        doi: "10.1016/j.gene.2021.145993",
        date: "2021",
        jour: "Gene",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000182",
        pmid: "33200776",
        title: "kTWAS: integrating kernel machine with transcriptome-wide association studies improves statistical power and reveals novel genes",
        doi: "10.1093/bib/bbaa270",
        date: "2020",
        jour: "Briefings in Bioinformatics",
        repTraN: "6",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000183",
        pmid: "33481017",
        title: "Multi-omics analysis to identify susceptibility genes for colorectal cancer",
        doi: "10.1093/hmg/ddab021",
        date: "2021",
        jour: "Human Molecular Genetics",
        repTraN: "1",
        expreLink: "https://portal.gdc.cancer.gov/",
    },
    {
        stuId: "TWASP000184",
        pmid: "34675350",
        title: "Multi-tissue transcriptome-wide association study identifies eight candidate genes and tissue-specific gene expression underlying endometrial cancer susceptibility",
        doi: "10.1038/s42003-021-02745-3",
        date: "2021",
        jour: "Communication Biology",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000185",
        pmid: "34317694",
        title: "Pleiotropy-guided transcriptome imputation from normal and tumor tissues identifies candidate susceptibility genes for breast and ovarian cancer",
        doi: "10.1016/j.xhgg.2021.100042",
        date: "2021",
        jour: "Human Genetics and Genomics Advances",
        repTraN: "3",
        expreLink: "https://gtexportal.org/;https://portal.gdc.cancer.gov/",
    },
    {
        stuId: "TWASP000186",
        pmid: "34286847",
        title: "Transcriptome-wide association study for inflammatory bowel disease reveals novel candidate susceptibility  genes in specific colon subsites and tissue categories",
        doi: "10.1093/ecco-jcc/jjab131",
        date: "2022",
        jour: "Journal of Crohn's and Colitis",
        repTraN: "3",
        expreLink:
            "https://gtexportal.org/;https://www.ebi.ac.uk/arrayexpress/E-MTAB-6667;https://www.ebi.ac.uk/ebisearch/search.ebi?db=allebi&query=EGAS00001004891&requestFrom=searchBox",
    },
    {
        stuId: "TWASP000187",
        pmid: "34356065",
        title: "Transcriptome-wide association  study of blood cell  traits in African ancestry  and Hispanic/Latino populations",
        doi: "10.3390/genes12071049",
        date: "2021",
        jour: "Genes",
        repTraN: "4",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE56045;https://www.nimhgenetics.org/",
    },
    {
        stuId: "TWASP000188",
        pmid: "35347136",
        title: "Nasal airway transcriptome-wide association study of asthma reveals genetically driven mucus pathobiology",
        doi: "10.1038/s41467-022-28973-7",
        date: "2022",
        jour: "Nature Communications",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE152004;https://gtexportal.org/",
    },
    {
        stuId: "TWASP000189",
        pmid: "35354918",
        title: "Transcriptome-wide association study reveals increased neuronal FLT3 expression is associated with Tourette's syndrome",
        doi: "10.1038/s42003-022-03231-0",
        date: "2022",
        jour: "Communication Biology",
        repTraN: "1",
        expreLink:
            "https://www.med.upenn.edu/cndr/datasharing.html;http://icahn.mssm.edu/research/labs/neuropathology-and-brain-banking;https://gtexportal.org",
    },
    {
        stuId: "TWASP000190",
        pmid: "35241742",
        title: "Gene-based association tests using GWAS summary statistics and incorporating eQTL",
        doi: "10.1038/s41598-022-07465-0",
        date: "2022",
        jour: "Science Reports",
        repTraN: "1",
        expreLink:
            "https://www.ncbi.nlm.nih.gov/gap/?term=phs000486.v1.p1;http://gusevlab.org/projects/fusion",
    },
    {
        stuId: "TWASP000191",
        pmid: "35175464",
        title: "Transcriptome-wide association study of coronary artery disease identifies novel susceptibility genes",
        doi: "10.1007/s00395-022-00917-8",
        date: "2022",
        jour: "Basic Research in Cardiology",
        repTraN: "1",
        expreLink: "http://starnet.mssm.edu/;https://gtexportal.org/",
    },
    {
        stuId: "TWASP000192",
        pmid: "35145551",
        title: "Machine learning revealed ferroptosis features and a novel ferroptosis-based classification for diagnosis in acute myocardial infarction",
        doi: "10.3389/fgene.2022.813438",
        date: "2022",
        jour: "Frontiers in Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000193",
        pmid: "35138379",
        title: "Transcriptome-wide association study in UK biobank Europeans identifies associations with blood cell traits",
        doi: "10.1093/hmg/ddac011",
        date: "2022",
        jour: "Human Molecular Genetics",
        repTraN: "5",
        expreLink: "https://www.nimhgenetics.org/",
    },
    {
        stuId: "TWASP000194",
        pmid: "35058554",
        title: "Cross-tissue transcriptome-wide association studies identify susceptibility genes shared between schizophrenia and inflammatory bowel disease",
        doi: "10.1038/s42003-022-03031-6",
        date: "2022",
        jour: "Communication Biology",
        repTraN: "6",
        expreLink:
            "https://gtexportal.org/;https://www.ebi.ac.uk/gxa/experiments/E-MTAB-6814;https://www.ebi.ac.uk/gxa/experiments/E-MTAB-5015;https://www.ebi.ac.uk/gxa/experiments/E-MTAB-3827;https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE125970",
    },
    {
        stuId: "TWASP000195",
        pmid: "35043938",
        title: "Accounting for nonlinear effects of gene expression identifies additional associated genes in transcriptome-wide association studies",
        doi: "10.1093/hmg/ddac015",
        date: "2022",
        jour: "Human Molecular Genetics",
        repTraN: "1",
        expreLink: "https://gtexportal.org/",
    },
    {
        stuId: "TWASP000196",
        pmid: "35038056",
        title: "Identifying candidate genes associated with sporadic amyotrophic lateral sclerosis via integrative analysis of transcriptome-wide association study and messenger RNA expression profile",
        doi: "10.1007/s10571-021-01186-0",
        date: "2022",
        jour: "Cell Molecular Neurobiology",
        repTraN: "1",
        expreLink: "http://gusevlab.org/projects/fusion/",
    },
    {
        stuId: "TWASP000197",
        pmid: "34962261",
        title: "Transcriptome-wide association study identifies multiple genes and pathways associated with thyroid function",
        doi: "10.1093/hmg/ddab371",
        date: "2021",
        jour: "Human Molecular Genetics",
        repTraN: "3",
        expreLink: "https://gtexportal.org/;https://www.ncbi.nlm.nih.gov/gap/?term=phs000486.v1.p1",
    },
    {
        stuId: "TWASP000198",
        pmid: "34942230",
        title: "A novel cis-regulatory variant modulating TIE1 expression associated with attention deficit hyperactivity disorder in Han Chinese children",
        doi: "10.1016/j.jad.2021.12.066",
        date: "2022",
        jour: "Journal of Affective Disorders",
        repTraN: "1",
        expreLink: "https://gtexportal.org/;http://cnsgenomics.com/software/smr/#Download",
    },
    {
        stuId: "TWASP000199",
        pmid: "34779012",
        title: "A large-scale transcriptome-wide association study (TWAS) of 10 blood cell phenotypes reveals complexities of TWAS fine-mapping",
        doi: "10.1002/gepi.22436",
        date: "2021",
        jour: "Genetic Epidemiology",
        repTraN: "8",
        expreLink: "https://www.nimhgenetics.org/",
    },
    {
        stuId: "TWASP000200",
        pmid: "34387340",
        title: "A transcriptome-wide association study identifies novel blood-based gene biomarker candidates for Alzheimer's disease risk",
        doi: "10.1093/hmg/ddab229",
        date: "2022",
        jour: "Human Molecular Genetics",
        repTraN: "1",
        expreLink: "https://www.ncbi.nlm.nih.gov/gap/?term=phs000424.v6.p1",
    },
];

export const traitpub = [
    {
        stuId: "TWASP000088",
        repTra: "1,5-Anhydroglucitol",
        traTy: null,
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "13",
        assoc: "15",
        method: "Summary-PrediXcan(S-PrediXcan)",
        gwasP: "European",
    },
];

export const traitassoc = [
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Adipose",
        geneSym: "MCM6",
        geneId: "ENSG00000076003.4",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "6.05E+00",
        pvalue: "1.46E-09",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Aortic Artery",
        geneSym: "MCM6",
        geneId: "ENSG00000076003.4",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "5.25E+00",
        pvalue: "1.51E-07",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Esophagus Gastroesophageal",
        geneSym: "MCM6",
        geneId: "ENSG00000076003.4",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "6.02E+00",
        pvalue: "1.72E-09",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Brain Hippocampus",
        geneSym: "DARS1",
        geneId: "ENSG00000115866",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "4.28E+00",
        pvalue: "1.90E-05",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Stomach",
        geneSym: "MCM6",
        geneId: "ENSG00000076003.4",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "4.76E+00",
        pvalue: "1.95E-06",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Skin Not Sun Exposed",
        geneSym: "UBXN4",
        geneId: "ENSG00000144224.16",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "4.25E+00",
        pvalue: "2.17E-05",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Whole Blood",
        geneSym: "ZRANB3",
        geneId: "ENSG00000121988.17",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "4.63E+00",
        pvalue: "3.65E-06",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Artery Tibia",
        geneSym: "MCM6",
        geneId: "ENSG00000076003.4",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "4.11E+00",
        pvalue: "3.98E-05",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Whole Blood",
        geneSym: "MCM6",
        geneId: "ENSG00000076003.4",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "5.06E+00",
        pvalue: "4.13E-07",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Esophagus Muscularis",
        geneSym: "DARS1",
        geneId: "ENSG00000115866",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "4.07E+00",
        pvalue: "4.67E-05",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Brain Hippocampus",
        geneSym: "MCM6",
        geneId: "ENSG00000076003.4",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "6.55E+00",
        pvalue: "5.64E-11",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Heart Left Ventricle",
        geneSym: "MCM6",
        geneId: "ENSG00000076003.4",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "4.48E+00",
        pvalue: "7.30E-06",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Small Intestine",
        geneSym: "UBXN4",
        geneId: "ENSG00000144224.16",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "4.92E+00",
        pvalue: "8.71E-07",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Colon Sigmoid",
        geneSym: "MCM6",
        geneId: "ENSG00000076003.4",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "6.13E+00",
        pvalue: "8.79E-10",
        stuId: "TWASP000088",
    },
    {
        repTra: "1,5-Anhydroglucitol",
        mapTra: "1,5-Anhydroglucitol Measurement",
        tissue: "Nerve Tibial",
        geneSym: "UBXN4",
        geneId: "ENSG00000144224.16",
        geneTy: "protein-coding",
        method: "Summary-PrediXcan(S-PrediXcan)",
        zscore: "5.33E+00",
        pvalue: "9.96E-08",
        stuId: "TWASP000088",
    },
];

import geneassoc from "@/mock/geneassoc";
import geneeqtl from "@/mock/geneeqtl";
import publicationtrait from "@/mock/publicationtrait";
import publicationassoc from "@/mock/publicationassoc";
export { geneassoc, geneeqtl, publicationtrait, publicationassoc };
