<template>
    <div class="container-publication">
        <div class="pubTitle">
            <h3>{{ detailInfo.pmid }}</h3>
        </div>

        <div class="tableC">
            <h3>Publication information</h3>
        </div>
        <table class="table table-bordered table-hover">
            <tbody>
                <tr>
                    <td>PMID</td>
                    <td>
                        <a
                            target="_blank"
                            style="color: #176b6e"
                            :href="`https://pubmed.ncbi.nlm.nih.gov/${detailInfo.pmid}`"
                            >{{ detailInfo.pmid }}</a
                        >
                    </td>
                </tr>
                <tr>
                    <td>Title</td>
                    <td>{{ detailInfo.title }}</td>
                </tr>
                <tr>
                    <td>DOI</td>
                    <td>
                        <a
                            target="_blank"
                            style="color: #176b6e"
                            :href="`https://doi.org/${detailInfo.doi}`"
                            >{{ detailInfo.doi }}</a
                        >
                    </td>
                </tr>
                <tr>
                    <td>Journal</td>
                    <td>{{ detailInfo.journal }}</td>
                </tr>
                <tr>
                    <td>Year</td>
                    <td>{{ detailInfo.year }}</td>
                </tr>
            </tbody>
        </table>

        <div class="tableT">All traits reported in this publication</div>

        <publication_trait :publicationId="publicationId" />

        <!--
        <div class="tableT">Associations reported in this publication</div>
        <div class="header-tool">
            <input class="form-control search-input" />
            <button-group>
                <i class="glyphicon glyphicon-download-alt"></i>
                <template slot="button-list">
                    <li>
                        <a>CSV</a>
                    </li>
                    <li>
                        <a>TXT</a>
                    </li>
                    <li>
                        <a>MS-Excel</a>
                    </li>
                </template>
            </button-group>
        </div>

        <t-table :data="publicationassoc">
            <t-table-column label="Reported Trait" property="repTra" width="6%" />
            <t-table-column label="Mapped Trait" property="mapTra" width="6%">
                <a slot-scope="{ row }" :href="`/trait/${row.mapTra}`">{{ row.mapTra }}</a>
            </t-table-column>
            <t-table-column label="Tissue" property="tissue" width="6%" />
            <t-table-column label="Gene Symbol" property="geneSym" width="6%">
                <a slot-scope="{ row }" :href="`/genedetail/${row.geneId}`">{{ row.geneSym }}</a>
            </t-table-column>
            <t-table-column label="Ensembl ID" property="geneId" width="6%">
                <a slot-scope="{ row }" :href="`/genedetail/${row.geneId}`">{{ row.geneId }}</a>
            </t-table-column>
            <t-table-column label="Gene Type" property="geneTy" width="6%" />
            <t-table-column label="Method/Software" property="method" width="6%" />
            <t-table-column label="Effect Size" property="zscore" width="6%" />
            <t-table-column label="P-value" property="pvalue" width="6%" />
        </t-table>
        -->
    </div>
</template>

<script>
import Publication_trait from "@/views/publication/publication_trait";
export default {
    name: "publication",
    components: { Publication_trait },
    data() {
        return {
            publicationId: this.$route.params.id,
            detailInfo: {
                journal: "",
                doi: "",
                year: "",
            },
        };
    },

    created() {
        this.getDetail();
    },

    methods: {
        async getDetail() {
            let res = await this.$ajax.get(`publication/${this.publicationId}`);
            this.detailInfo = res.data;
        },
    },
};
</script>

<style lang="less" scoped>
@import "~@/common/style/traDetail";

.container-publication {
    padding: 0 45px;
}
.pubTitle {
    h3 {
        color: #176b6e;
    }
}

.table-bordered {
    tr {
        td:first-child {
            color: #176b6e;
            width: 150px;
        }
    }
}
</style>
