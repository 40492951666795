<template>
    <div class="container-help">
        <div class="top-title">常见问题解答</div>
        <ul class="help-content">
            <template v-for="item in helpList">
                <li class="title" :key="item.title">{{ item.title }}</li>
                <p class="content" :key="item.content">{{ item.content }}</p>
            </template>
        </ul>
    </div>
</template>

<script>
const helpList = new Array(5)
    .fill({
        title: "问题",
        content: "解答",
    })
    .map((v) => Object.assign({}, v));

helpList.forEach((item, i) => {
    item.title = `${item.title}-${i}`;
    item.content = `${item.content}-${i}`.repeat(300);
});

export default {
    name: "help",

    data() {
        return {
            helpList,
        };
    },

    methods: {},
};
</script>

<style lang="less" scoped>
.container-help {
    padding: 30px;

    .top-title {
        font-size: 24px;
    }

    li {
        font-size: 20px;
    }

    p {
        font-size: 17px;
    }
}
</style>
