<template>
    <div class="nav-footer">
    </div>
</template>

<script>
export default {
    name: "nav-footer",

    data() {
        return {};
    },

    methods: {},
};
</script>

<style lang="less" scoped>
.nav-footer {
    border-top: 3px solid #0c64b6;
    padding: 15px 30px 10px 30px;
    background: #f9f9f9;

    .footer-hr {
        height: 0;
        padding: 0;
        margin: 10px 0 10px 0;
        color: #eee;
        border: 0;
        border-top: 1px solid #eee;
    }

    .link-container {
        .link-box {
            width: 16%;
            display: inline-flex;
            flex-direction: column;

            .link-title {
                font-weight: bold;
            }

            .link-item {
                display: inline-block;
            }
        }
    }

    .web-info {
        display: flex;
        justify-content: space-between;

        .link-i {
            padding: 0 5px;
        }
    }
}
</style>
