import Vue from "vue";
import VueRouter from "vue-router";

import home from "../views/home.vue";
import browse from "../views/browse.vue";
import search from "../views/search.vue";
import downloads from "../views/downloads.vue";
import document from "../views/document.vue";
import help from "../views/help.vue";
import submit from "../views/submit.vue";
import trait from "../views/trait.vue";
import genedetail from "../views/genedetail.vue";
import publication from "../views/publication.vue";
import snp from "../views/snp.vue";
import browseTraits from "../views/browse/traits";
import browseGenes from "../views/browse/genes";
import browsePublications from "../views/browse/publications";
import browseSnp from "../views/browse/snp";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        alias: "/home",
        component: home,
        meta: {
            title: "Home",
        }
    },
    {
        path: "/browse",
        name: "browse",
        component: browse,
        meta: {
            title: "Browse",
        }
    },
    {
        path: "/document",
        name: "document",
        component: document,
        meta: {
            title: "Documentation",
        }
    },
    {
        path: "/help",
        name: "help",
        component: help,
        meta: {
            title: "Help",
        }
    },
    {
        path: "/search",
        name: "search",
        component: search,
        meta: {
            title: "Search",
        }
    },
    {
        path: "/submit",
        name: "submit",
        component: submit,
        meta: {
            title: "Submit",
        }
    },
    {
        path: "/downloads",
        name: "downloads",
        component: downloads,
        meta: {
            title: "Downloads",
        }
    },
    {
        path: "/trait/:id",
        name: "trait",
        component: trait,
        meta: {
            title: "trait",
        }
    },
    {
        path: "/genedetail/:id",
        name: "genedetail",
        component: genedetail,
        meta: {
            title: "genedetail",
        }
    },
    {
        path: "/publication/:id",
        name: "publication",
        component: publication,
        meta: {
            title: "publication",
        }
    },
    {
        path: "/snp/:id",
        name: "snp",
        component: snp,
        meta: {
            title: "Variant",
        }
    },
    {
        path: "/browseTraits",
        name: "browseTraits",
        component: browseTraits,
        meta: {
            title: "Traits",
        }
    },
    {
        path: "/browsePublications",
        name: "browsePublications",
        component: browsePublications,
        meta: {
            title: "Publications",
        }
    },
    {
        path: "/browseGenes",
        name: "browseGenes",
        component: browseGenes,
        meta: {
            title: "Genes",
        }
    },
    {
        path: "/browseSnp",
        name: "browseSnp",
        component: browseSnp,
        meta: {
            title: "Variant",
        }
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;
