<template>
    <div style="padding: 20px;">
        <div class="header-tool">
            <input @input="handleSearchInput" class="form-control search-input" />
            <button-group>
                <i class="glyphicon glyphicon-download-alt"></i>
                <template slot="button-list">
<!--                    <li>-->
<!--                        <a @click="handleTableExportCsv">CSV</a>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <a @click="handleTableExportTxt">TXT</a>-->
<!--                    </li>-->
                    <li>
                        <a @click="handleExportExcel">MS-Excel</a>
                    </li>
                </template>
            </button-group>
        </div>

        <t-table
            id="mainTable"
            :data="tableData.data"
            :pageSize="queryTableParams.pageSize"
            :curPage="queryTableParams.pageNum"
            :total="tableData.total"
            @current-change="handleTableCurrentChange"
            @sort-change="handleSortable"
            @size-change="handleTablePageSize"
        >
            <t-table-column label="Gene Symbol" property="symbol" width="6%" sortable>
                <template slot-scope="{ row }">
                    <router-link :to="`/genedetail/${row.id}`">{{ row.symbol }}</router-link>
                </template>
            </t-table-column>
            <t-table-column label="Ensembl ID" property="ensgId" width="6%" sortable>
                <template slot-scope="{ row }">
                    <router-link :to="`/genedetail/${row.id}`">{{ row.ensgId }}</router-link>
                </template>
            </t-table-column>
            <t-table-column label="Location" property="location" width="10%" sortable/>
            <t-table-column label="Gene Type" property="type" width="6%" />
            <t-table-column label="Synonyms" property="synonyms" width="15%" />
            <!-- <t-table-column label="#Associated Traits" property="assoTraN" width="6%" /> -->
            <t-table-column
                label="Number of Associated Traits"
                property="traits"
                width="4%" sortable
            >
                <template slot-scope="{ row }">
                    <span>{{row.traits}}</span>
                </template>
            </t-table-column>
        </t-table>
    </div>
</template>

<script>
import table_mix from "@/common/table_mix";

export default {
    name: "genes",
    mixins: [table_mix],
    data() {
        return {};
    },

    computed: {
        getTableDataAjaxUrl() {
            return "gene/page";
        },
    },

    created() {
        this.refreshTable();
    },

    methods: {
        handleExportExcel() {
            let params = this.queryTableParams;
            this.$ajax.download("gene/downloadExcel", params);
        }
    },
};
</script>

<style lang="less" scoped>
.header-tool {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.search-input {
    display: inline-block;
    width: 200px;
}
</style>
