<template>
    <div>
        <div class="header-tool">
            <input @input="handleSearchInput" class="form-control search-input" />
            <button-group>
                <i class="glyphicon glyphicon-download-alt"></i>
                <template slot="button-list">
                    <li>
                        <a @click="handleTableExportCsv">CSV</a>
                    </li>
                    <li>
                        <a @click="handleTableExportTxt">TXT</a>
                    </li>
                    <li>
                        <a @click="handleTableExportExcel">MS-Excel</a>
                    </li>
                </template>
            </button-group>
        </div>
        <t-table
            id="mainTable"
            :data="tableData.data"
            :pageSize="queryTableParams.pageSize"
            :curPage="queryTableParams.pageNum"
            :total="tableData.total"
            @current-change="handleTableCurrentChange"
            @sort-change="handleSortable"
            @size-change="handleTablePageSize"
        >
            <t-table-column label="Reported Trait" property="reportedTrait" width="12%" sortable/>
            <t-table-column label="EFO Trait Label" property="traitLabel" width="12%" sortable>
                <template slot-scope="{ row }">
                    <span>{{ row.traitLabel }}</span>
                </template>
            </t-table-column>
<!--            <t-table-column label="Trait Type" property="traitType" width="12%">-->
<!--                <template slot-scope="{ row }">-->
<!--                    <span>{{ row.traitType }}</span>-->
<!--                </template>-->
<!--            </t-table-column>-->
            <t-table-column label="#Associations" property="association" width="6%" sortable />
            <t-table-column label="TraitCategory" property="traitCategory" width="6%" />
        </t-table>
    </div>
</template>

<script>
import table_mix from "@/common/table_mix";
export default {
    name: "publication_trait",

    mixins: [table_mix],

    props: {
        publicationId: String,
    },

    data() {
        return {};
    },

    created() {
        this.refreshTable();
    },

    computed: {
        getTableDataAjaxUrl() {
            return `publication/${this.publicationId}/gene/page`;
        },
    },

    methods: {},
};
</script>

<style scoped></style>
