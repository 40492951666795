<template>
    <div class="nav-header">
        <!--
        <div class="top-header">
            <div>
                <span>图标-超链接</span>
            </div>
            <div class="a-link">
                <span v-for="i in [1, 2, 3, 4, 5]" :key="i">超链接{{ i }}</span>
            </div>
        </div>
        -->

        <div class="menu-navigation">
            <div>
                <img src="/images/logo.jpg" style="width: 80px; margin-left: 15px;">
                <span style="margin-left: 10px; font-size: 26px;"><a href='/' style="color: white; text-decoration: none;">RAVAR: RAre Variant Association Repository</a></span>
            </div>
            <div class="menu-box">
                <router-link class="menu-item" v-for="menu in menuList" :key="menu.path" :to="menu.path">
                    <i :class="menu.svgIcon"></i>
                    <span>{{ menu.title }}</span>
                </router-link>
            </div>
        </div>

        <div class="nav-breadcrumb" v-if="$route.name !== 'home'">
            <router-link to="/">Home</router-link>
            <template v-if="isBrowse">
                <span class="cut">/</span>
                <router-link v-if="isBrowse" to="/browse">browse</router-link>
            </template>
            <span class="cut">/</span>
            <span>{{ breadcrumb }}</span>
        </div>
    </div>
</template>

<script>

const menuList = [
    {
        svgIcon: "glyphicon glyphicon-home",
        title: "Home",
        path: "/home",
    },
    /*
    {
        svgIcon: "glyphicon glyphicon-eye-open",
        title: "Browse",
        path: "/browse",
    },
     */
    {
        svgIcon: "glyphicon glyphicon-list",
        title: "Traits",
        path: "/browseTraits",
    },{
        svgIcon: "glyphicon glyphicon-leaf",
        title: "Genes",
        path: "/browseGenes",
    },{
        svgIcon: "glyphicon glyphicon-tree-conifer",
        title: "Variants",
        path: "/browseSnp",
    }, {
        svgIcon: "glyphicon glyphicon-book",
        title: "Publications",
        path: "/browsePublications",
    }, {
        svgIcon: "glyphicon glyphicon-search",
        title: "Search",
        path: "/search",
    }, {
        svgIcon: "glyphicon glyphicon-upload",
        title: "Submit",
        path: "/submit",
    }, {
        svgIcon: "glyphicon glyphicon-download-alt",
        title: "Downloads",
        path: "/downloads",
    }, {
        svgIcon: "glyphicon glyphicon-file",
        title: "Documentation",
        path: "/document",
    }
]

export default {
    name: "nav-header",

    data() {


        return {
            menuList,
        };
    },

    computed: {
        breadcrumb() {
            return this.$route.meta?.title;
        },
        isBrowse() {
            let d = [
                "trait",
                "genedetail",
                "publication",
            ];
            return d.includes(this.$route.name);
        }
    },

    methods: {

    },
};
</script>

<style lang="less" scoped>
.nav-header {
    .top-header {
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #0c64b6;
        color: #ffffff;

        .a-link {
            span {
                margin-right: 16px;
            }
        }
    }

    .menu-navigation {
        color: #ffffff;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #32587f;

        .menu-box {
            padding-right: 20px;

            .menu-item {
                color: #ffffff;
                display: inline-block;
                padding: 6px 9px;
                font-size: 18px;

                span {
                    //font-size: 20px;
                }

                i {
                    margin-right: 8px;
                }

                &:not(:last-child) {
                    border-right: 1px solid #ffffff;
                }
            }
        }
    }

    .nav-breadcrumb {
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        background: #f4f4f4;
        font-size: 16px;
        font-weight: bold;
        color: #176b6e;
        a {
            color: #176b6e;

            &:hover {
                text-decoration: underline;
            }
        }

        .cut {
            content: "/";
            padding: 5px 5px 0;
        }
    }
}
</style>
