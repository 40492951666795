import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import select from "@/components/select/select";
import buttonGroup from "@/components/btn-group";
import tTable from "@/components/table/tTable";
import tTableColumn from "@/components/tTableColumn";
import "bootstrap/dist/css/bootstrap.min.css";
import Plotly from "plotly.js-dist";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ajax from "@/api/base";
import "element-ui/lib/theme-chalk/index.css";
import * as echarts from "echarts";

Vue.config.productionTip = false;
Vue.component(select.name, select);
Vue.component(tTable.name, tTable);
Vue.component(tTableColumn.name, tTableColumn);
Vue.component("button-group", buttonGroup);

import ElementUI from "element-ui";
Vue.use(ElementUI);

Vue.prototype.$plotly = Plotly;

Vue.prototype.$ajax = ajax;
Vue.prototype.$ = window.$;
Vue.prototype.$echarts = echarts;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
