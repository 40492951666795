<template>
    <div>
        <t-table
            id="mainTable"
            :data="tableData.data"
            :pageSize="queryTableParams.pageSize"
            :curPage="queryTableParams.pageNum"
            :total="tableData.total"
            @current-change="handleTableCurrentChange"
            @sort-change="handleSortable"
            @size-change="handleTablePageSize"
        >
            <t-table-column label="Gene Symbol" property="symbol" width="4%" />
            <t-table-column label="Ensembl ID" property="ensgId" width="8%" />
            <t-table-column label="Gene Type" property="type" width="8%" />
            <t-table-column label="CHR" property="chr" width="4%" />
            <t-table-column label="Location" property="location" width="10%" />
            <t-table-column label="Reported Trait" property="reportedTrait" width="16%" />
            <t-table-column label="EFO Trait Label" property="trait" width="10%" />
            <t-table-column label="Trait Ontology ID" property="ontologyId" width="6%" />
            <t-table-column label="P-Value" property="pvalue" width="6%" />
        </t-table>
    </div>
</template>

<script>
import table_mix from "@/common/table_mix";
export default {
    name: "genedetail_trait",

    mixins: [table_mix],

    props: {
        detailId: String,
    },

    data() {
        return {};
    },

    created() {
        this.refreshTable();
    },

    computed: {
        getTableDataAjaxUrl() {
            return `gene/${this.detailId}/trait/page`;
        },
    },

    methods: {},
};
</script>

<style scoped></style>
