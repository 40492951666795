<template>
    <div>
        <div class="header-tool">
            <input @input="handleSearchInput" class="form-control search-input" />
            <button-group>
                <i class="glyphicon glyphicon-download-alt"></i>
                <template slot="button-list">
                    <li>
                        <a @click="handleExportExcel">MS-Excel</a>
                    </li>
                </template>
            </button-group>
        </div>
        <t-table
            id="mainTable"
            :data="tableData.data"
            :pageSize="queryTableParams.pageSize"
            :curPage="queryTableParams.pageNum"
            :total="tableData.total"
            @current-change="handleTableCurrentChange"
            @sort-change="handleSortable"
            @size-change="handleTablePageSize"
        >
            <t-table-column label="RSID" property="snpId" width="10%" sortable/>
            <t-table-column label="CHR" property="chr" width="5%" sortable/>
            <t-table-column label="EFO Code" property="ontologyId" width="6%" sortable />
            <t-table-column label="EFO Ontology Trait" property="mappedTrait" width="26%" />
            <t-table-column label="MAF" property="maf" width="16%" sortable/>
            <t-table-column label="P-Value" property="pvalue" width="16%" sortable/>
        </t-table>
    </div>
</template>

<script>
import table_mix from "@/common/table_mix";
export default {
    name: "trait_snp",

    mixins: [table_mix],

    props: {
        traitId: String,
    },

    data() {
        return {};
    },

    created() {
        this.refreshTable();
    },

    computed: {
        getTableDataAjaxUrl() {
            return `trait/${this.traitId}/snp/page`;
        },
    },

    methods: {
        handleExportExcel() {
            let params = this.queryTableParams;
            this.$ajax.download(`trait/${this.traitId}/snp/list`, params);
        },
    },
};
</script>

<style scoped></style>
